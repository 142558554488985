import React from "react";
import "./product-list.css";
import { Link } from "react-router-dom";
import { BaseService } from "../../services/base-service";
//

export class ProductItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  getPriceString = (price) => {
    price = price.toString();
    return `${
      BaseService.stringIsNullOrWhiteSpace(price)
        ? "0"
        : BaseService.formatNumber(price.toString())
    }đ`;
  };

  addProductToCart = () => {
    // ProductService.addProductToCart(this.props.productItem);
    this.props.addProductItemToCart(this.props.productItem);
  };

  renderSecondImage = () => {
    const html = (
      <img
        alt=""
        src={this.props.productItem.secondImgURL}
        className="product-list-carousel-item-img"
      />
    );
    return html;
  };

  renderAddToCartElement = () => {
    const element = (
      <div
        className="product-item-cart-div"
        onClick={(e) => {
          e.preventDefault();
        }}
      >
        <div
          className="product-item-cart-item-div product-item-cart-buy-item-div"
          onClick={this.addProductToCart}
        >
          Thêm vào giỏ hàng
        </div>
        {/* <div
      className="product-item-cart-item-div product-item-cart-buy-item-div"
      onClick={this.addProductToCart}
    >
      <div className="product-item-cart-icon-div">
        <i className="fas fa-cart-plus"></i>
      </div>
      <div className="product-item-cart-text-div">Mua ngay</div>
    </div> */}
        {/* <div className="product-item-cart-item-div">
      <div className="product-item-cart-icon-div">
        <i className="fas fa-eye"></i>
      </div>
      <div className="product-item-cart-text-div">Chi tiết</div>
    </div> */}
      </div>
    );
    return element;
  };

  renderAddToCartButtonElement = () => {
    const element = (
      <div
        className="product-list-carousel-item-add-to-cart-button-div"
        onClick={(e) => {
          e.preventDefault();
          this.addProductToCart();
        }}
      >
        Thêm vào giỏ
      </div>
    );
    return element;
  };

  render() {
    const html = (
      <>
        <div className="product-list-carousel-item-outer-div">
          <Link
            // to={`/san-pham/${BaseService.transformVNIToHyphenCase(
            //   this.props.productItem.categoryName
            // )}/${this.props.productItem.titleId}`}
            to={`/${this.props.productItem.titleId}`}
            className="product-list-carousel-item-link"
            title={this.props.productItem.title}
          >
            <div className="product-list-carousel-item-div">
              {this.props.productItem.saleInfo &&
              this.props.productItem.saleInfo.sale11 === true ? (
                <div className="product-list-carousel-sale-item-div">
                  {/* <i className="fas fa-tag"></i> */}
                </div>
              ) : (
                <></>
              )}
              {/* <div className='product-list-carousel-item-status-div'>
                            <div className='product-list-carousel-item-status-new-div' title='Mới'><i className="fas fa-star"></i></div>
                            <div className='product-list-carousel-item-status-hot-div' title='Hot'><i className="fas fa-fire"></i></div>
                            {
                                (this.props.productItem.isNew === true)?(
                                    <div className='product-list-carousel-item-status-hot-div' title='Hot'><i className="fas fa-fire"></i></div>
                                ):(<></>)
                            }
                        </div> */}
              <div className="product-list-carousel-item-img-container-div">
                <img
                  alt=""
                  src={this.props.productItem.imgURL}
                  className="product-list-carousel-item-img"
                />
                {this.renderSecondImage()}
              </div>
              <div className="product-list-carousel-title-div">{`${this.props.productItem.name}`}</div>
              <div className="product-item-content-div">
                <div className="product-list-carousel-price-div">
                  <span>
                    {this.getPriceString(this.props.productItem.price)}
                  </span>
                  <span />
                  {this.props.productItem.price <
                  this.props.productItem.originalPrice ? (
                    <>
                      <span>
                        {this.getPriceString(
                          this.props.productItem.originalPrice
                        )}
                      </span>
                      <span className="product-list-carousel-discount-percent-span">
                        {" "}
                        -{this.props.productItem.discountPercent}%
                      </span>
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="product-list-carousel-promo-text-div">
                    {this.props.productItem.promoText}
                  </div>
                  {this.renderAddToCartButtonElement()}
                </div>
                <div className="product-list-carousel-cart-div">
                  {/* <div
                    className={`product-list-carousel-item-status-is-flash-sale-div${
                      this.props.productItem.saleInfo &&
                      this.props.productItem.saleInfo.sale11 === true
                        ? " product-list-carousel-item-status-show-div"
                        : ""
                    }`}
                  >
                    Flash sale
                  </div> */}
                  <div
                    className={`product-list-carousel-item-status-is-new-div${
                      this.props.productItem.isNew === true
                        ? " product-list-carousel-item-status-show-div"
                        : ""
                    }`}
                  >
                    Mới
                  </div>
                  <div
                    className={`product-list-carousel-item-status-is-hot-div${
                      this.props.productItem.isHot === true
                        ? " product-list-carousel-item-status-show-div"
                        : ""
                    }`}
                  >
                    Hot
                  </div>
                  {this.props.productItem.isNew !== true &&
                  this.props.productItem.isHot !== true ? (
                    <div className="product-list-carousel-item-status-temp-div">
                      |
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <div className='product-list-carousel-cart-button-div'>
                                    <i className='fas fa-cart-plus product-list-carousel-cart-icon-i'></i>
                                </div> */}
                </div>
                {/* {this.renderAddToCartElement()} */}
              </div>
              {/* <div className='product-list-carousel-disable-price-div'>300.000 đ</div> */}
            </div>
          </Link>
        </div>
      </>
    );
    return html;
  }
}
