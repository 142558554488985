import React from "react";
import "./typical-face-star-list.css";
//
import { Row, Col, Carousel } from "react-bootstrap";
import { BaseService } from "../../services/base-service";
import { RequestStatus } from "../../constants";
import { Link } from "react-router-dom";

export class TypicalFaceStarList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: "_blank",
      typicalFaceStarList: [],
    };
  }

  componentDidMount() {
    this.setImageHeight();
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.typicalFaceListRequestStatus !==
      nextProps.typicalFaceListRequestStatus &&
      nextProps.typicalFaceListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      const typicalFaceStarList = nextProps.typicalFaceStarList;
      let res = BaseService.getOrderListWithName(typicalFaceStarList);
      this.setState({
        typicalFaceStarList: res,
      });

      setTimeout(() => {
        console.log("TypicalFaceList - componentWillReceiveProps");
        this.handleResize();
        window.addEventListener("resize", this.handleResize);
      }, 0);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    this.setImageHeight();
  };

  setImageHeight = () => {
    BaseService.setHeightByWidthOfClass("typical-face-img-boder-div");
    setTimeout(() => {
      // BaseService.setMaxHeightByClassName('typical-face-description-div');
      setInterval(() => {
        BaseService.setMaxHeightByClassName("typical-face-carousel-item");
      }, 500);
      const TypicalFaceCarousel = document.getElementById(
        "TypicalFaceCarousel"
      );
      if (TypicalFaceCarousel) {
        TypicalFaceCarousel.classList.remove("typical-face-carousel");
      }
    }, 1000);
  };

  getTypicalFaceElement = (typicalFaceItem) => {
    const element = (
      <>
        <Row className="typical-face-list-container">
          <Col lg={1} className="d-none d-lg-block"></Col>
          <Col lg={5} md={6} className="d-none d-md-block">
            <Link to={`/guong-mat-nghe-si/${typicalFaceItem.id}`}>
              <img
                className="typical-face-img typical-star-face-img"
                alt=""
                src={typicalFaceItem.imgURL}
              />
            </Link>
          </Col>
          <Col lg={5} md={6}>
            <div className="typical-face-star-item-div">
              <Link to={`/guong-mat-nghe-si/${typicalFaceItem.id}`}>
                <div
                  className="typical-face-title-div"
                  dangerouslySetInnerHTML={{
                    __html: typicalFaceItem.displayName,
                  }}
                ></div>
                <div className="d-block d-md-none">
                  <img
                    className="typical-face-img"
                    alt=""
                    src={typicalFaceItem.imgURL}
                  />
                </div>
                <div className="typical-face-description-div">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: typicalFaceItem.description,
                    }}
                  ></div>
                </div>
              </Link>
              {!BaseService.stringIsNullOrWhiteSpace(typicalFaceItem.url) ? (
                <a
                  href={typicalFaceItem.url}
                  target={this.state.target}
                  className="typical-face-facebook-a"
                >
                  <i className="fab fa-facebook"></i> Xem thêm
                </a>
              ) : (
                <></>
              )}
            </div>
          </Col>
          <Col lg={1} className="d-none d-lg-block"></Col>
        </Row>
        <br />
      </>
    );
    return element;
  };

  render() {
    const html = (
      <>
        <div
          id="typicalFaceListContainerDiv"
          className="component-container-div typical-container-div"
        >
          {/* <div id='typicalFaceListContainerTitleDiv' className='component-title-div' style={{
                    backgroundImage: `url('${this.props.home_page_title_jpg}')`
                }}>
                    <div className='component-title-text-div'>
                        <div>
                            Nghệ sĩ đồng hành cùng kem sạch
                            <div className='component-title-hr'></div>
                        </div>
                    </div>
                </div> */}
          <div>
            <div
              id="typicalFaceListContainerTitleDiv"
              className="component-title-div"
              style={{
                backgroundImage: `url('${this.props.home_page_title_jpg}')`,
              }}
            >
              <div className="component-title-text-div component-kols-title-text-div">
                <div>
                  Founder & Celeb
                  {/* <div className="component-title-hr"></div> */}
                </div>
              </div>
            </div>
            <Carousel
              id="TypicalFaceCarousel"
              className="typical-face-carousel"
              controls={false}
              interval={5000}
            >
              {this.state.typicalFaceStarList.map((typicalFaceItem, index) => (
                <Carousel.Item
                  key={index}
                  className="typical-face-carousel-item"
                >
                  {this.getTypicalFaceElement(typicalFaceItem)}
                </Carousel.Item>
              ))}
            </Carousel>
          </div>
        </div>
      </>
    );
    return html;
  }
}
