import React from "react";
import "./product-list.css";
//
import { Row, Col } from "react-bootstrap";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ProductItem } from "./product-item";
import { BaseService } from "../../services/base-service";

export class ProductCategoryItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfProductItemPerSlide: 4,
      selectedTags: [],
      productItemList: [],
      carouselActiveIndex: 0,
    };
  }

  componentDidMount() {
    const windowWidth = this.props.windowWidth;
    this.setColSize(windowWidth);

    this.setStateProductItemList(this.props.productItemList);
  }

  setStateProductItemList = (originProductItemList) => {
    const productItemList = originProductItemList;
    let res = BaseService.getOrderListWithName(productItemList);
    this.setState(
      {
        productItemList: res,
      },
      () => {
        setTimeout(() => {
          this.setState({ carouselActiveIndex: 0 });
        }, 0);
      }
    );
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.windowWidth !== nextProps.windowWidth) {
      console.log("ProductCategoryItem - componentWillReceiveProps");
      const windowWidth = nextProps.windowWidth;
      this.setColSize(windowWidth);
    }

    if (this.props.productItemList !== nextProps.productItemList) {
      this.setStateProductItemList(nextProps.productItemList);
    }
  }

  setColSize = (windowWidth) => {
    let numberOfProductItemPerSlide = this.state.numberOfProductItemPerSlide;
    if (windowWidth >= 992) {
      numberOfProductItemPerSlide = 4;
    } else if (windowWidth >= 768) {
      numberOfProductItemPerSlide = 4;
    } else {
      numberOfProductItemPerSlide = 2;
    }
    console.log(windowWidth + "-" + numberOfProductItemPerSlide);
    this.setState({
      numberOfProductItemPerSlide: numberOfProductItemPerSlide,
    });
  };

  isSelectedTag = (id) => {
    const idIndex = this.state.selectedTags.findIndex((t) => t.id === id);
    return idIndex !== -1 ? true : false;
  };

  clickTag = (tagItem) => {
    let selectedTags = this.state.selectedTags;
    const idIndex = selectedTags.findIndex((t) => t.id === tagItem.id);
    if (idIndex === -1) {
      selectedTags.push(tagItem);
    } else {
      selectedTags.splice(idIndex, 1);
    }
    console.log("selectedTags", selectedTags);
    this.setState({
      selectedTags: selectedTags,
    });
  };

  getProductListElement = () => {
    let productItemList = this.state.productItemList;

    if (this.state.selectedTags.length > 0) {
      let filteredProductItemList = [];
      productItemList.forEach((item) => {
        let isShowItem = false;
        this.state.selectedTags.forEach((tag) => {
          if (!BaseService.stringIsNullOrWhiteSpace(item.tags)) {
            if (
              item.tags.toLowerCase().indexOf(tag.title.toLowerCase()) !== -1
            ) {
              isShowItem = true;
            }
          }
        });
        if (isShowItem) {
          filteredProductItemList.push(item);
        }
      });
      productItemList = filteredProductItemList;
      setTimeout(() => {
        this.setState({ carouselActiveIndex: 0 });
      }, 0);
    }

    if (productItemList && productItemList.length > 0) {
      let productListByCategoryGroup = [];
      let index = 0;
      do {
        const productListByCategoryGroupItem = productItemList.slice(
          index,
          index + this.state.numberOfProductItemPerSlide
        );
        productListByCategoryGroup.push(productListByCategoryGroupItem);
        index += this.state.numberOfProductItemPerSlide;
      } while (index < productItemList.length);
      const element = (
        <Carousel
          activeIndex={this.state.carouselActiveIndex}
          onSelect={(index) => {
            this.setState({ carouselActiveIndex: index });
          }}
          controls={false}
          interval={null}
          // indicators={(this.props.windowWidth <= 991)?(false):(true)}
          nextIcon={
            <i className="product-category-playback-icon fas fa-caret-right"></i>
          }
          prevIcon={
            <i className="product-category-playback-icon fas fa-caret-left"></i>
          }
        >
          {productListByCategoryGroup.map((groupItem, index) => (
            <Carousel.Item key={index}>
              {this.getProductListItemsElement(groupItem)}
            </Carousel.Item>
          ))}
        </Carousel>
      );
      return element;
    } else {
      return (
        <>
          <div className="product-list-no-have-product-div">
            Chưa có sản phẩm
          </div>
        </>
      );
    }
  };

  getProductListItemsElement = (productList) => {
    const element = (
      <Row className="product-list-carousel-div">
        {productList.map((productItem, index) => (
          <React.Fragment key={index}>
            <Col
              xs={6}
              md={3}
              lg={3}
              className="product-list-carousel-item-col"
            >
              <ProductItem
                productItem={productItem}
                addProductItemToCart={this.props.addProductItemToCart}
              />
            </Col>
          </React.Fragment>
        ))}
      </Row>
    );
    return element;
  };

  renderCustomeDisplay = () => {
    let html = (
      <Link to="/danh-sach-san-pham">
        <div
          dangerouslySetInnerHTML={{
            __html: this.props.productCategoryItem.displayName,
          }}
        ></div>
      </Link>
    );
    if (
      this.props.productCategoryItem.displayImage &&
      !BaseService.stringIsNullOrWhiteSpace(
        this.props.productCategoryItem.displayImage.name
      )
    ) {
      let displayImage = this.props.productCategoryItem.displayImage;
      if (
        this.props.productCategoryItem.displayImageMobile &&
        !BaseService.stringIsNullOrWhiteSpace(
          this.props.productCategoryItem.displayImageMobile.name
        ) &&
        this.props.windowWidth < 768
      ) {
        displayImage = this.props.productCategoryItem.displayImageMobile;
      }
      html = (
        <Link to="/danh-sach-san-pham">
          <img
            alt=""
            className="form-image-uploader-img"
            src={BaseService.getImageUrlFromServer(
              displayImage.id,
              displayImage.name
            )}
          />
        </Link>
      );
    }
    return html;
  };

  render() {
    const html = (
      <>
        <div className="product-list-container-div">
          <Row
            className="product-list-row-div"
            style={{
              backgroundImage: `url(${this.props.productCategoryItem.imgURL})`,
            }}
          >
            <Col
              md={
                this.props.productColumns ? 12 - this.props.productColumns : 2
              }
              lg={
                this.props.productColumns ? 12 - this.props.productColumns : 2
              }
              className="d-none d-md-block"
            >
              {/* <div className='product-list-catrgory-background-div' style={{
                            backgroundImage: `url('${this.props.productCategoryItem.imgURL}')`
                        }}>
                        </div> */}
            </Col>
            <Col
              md={this.props.productColumns ? this.props.productColumns : 10}
              lg={this.props.productColumns ? this.props.productColumns : 10}
            >
              <div className="product-list-catrgory-title-div">
                {this.props.productFilterTitle
                  ? this.props.productFilterTitle
                  : this.renderCustomeDisplay()}
                {/* <div>
                                <Link className='product-list-catrgory-title-link' to='/'>Tìm hiểu thêm <i className='fas fa-chevron-right'></i></Link>
                            </div> */}
              </div>
              {/* this.props.productCategoryItem.tags: {this.props.productCategoryItem.tags.length} */}
              {/* {this.state.productItemList &&
              this.state.productItemList.length > 0 ? (
                <div className="product-list-catrgory-tag-div">
                  {BaseService.getOrderListWithName(
                    this.props.productCategoryItem.tags
                  ).map((tagItem, index) => (
                    <React.Fragment key={index}>
                      <div
                        className={`product-list-catrgory-tag-item-div${
                          this.isSelectedTag(tagItem.id)
                            ? " product-list-catrgory-tag-selected-item-div"
                            : ""
                        }`}
                        onClick={() => {
                          this.clickTag(tagItem);
                        }}
                      >
                        # {tagItem.title}
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              ) : (
                <></>
              )} */}
              {this.getProductListElement()}
            </Col>
          </Row>
        </div>
      </>
    );
    return html;
  }
}
