import React from "react";
import "./event-list.css";
//
import { Row, Col } from "react-bootstrap";
import { BaseService } from "../../services/base-service";
// import { RequestStatus } from "../../constants";
import { Link } from "react-router-dom";
// import { EventStatus } from '../../constants';
import { ReadMoreButton } from "../public/form-components";

export class EventList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      eventList: [],
      limitDisplayNumber: 4,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.eventList !== nextProps.eventList) {
      setTimeout(() => {
        this.setStateEventList(0);
      }, 0);
    }
    // if (
    //   this.props.eventListRequestStatus !== nextProps.eventListRequestStatus &&
    //   nextProps.eventListRequestStatus === RequestStatus.RQ_SUCCESS
    // ) {
    //   setTimeout(() => {
    //     console.log("EventList - componentWillReceiveProps");
    //     this.handleResize();
    //     window.addEventListener("resize", this.handleResize);
    //   }, 0);
    // }
  }

  setStateEventList = (start) => {
    let propsEventList = BaseService.getOrderListWithName([
      ...this.props.eventList,
    ]);
    let stateEventList = [...this.state.eventList];
    if (stateEventList < propsEventList) {
      const newItems = propsEventList.splice(
        start,
        this.state.limitDisplayNumber
      );
      stateEventList = stateEventList.concat(newItems);
      this.setState({
        eventList: stateEventList,
      });
    }
  };

  readMoreEventList = () => {
    this.setStateEventList(this.state.eventList.length);
  };

  isReadMoreAvailable = () => {
    const res = this.state.eventList.length < this.props.eventList.length;
    return res;
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    BaseService.setMaxHeightByClassName("event-item-info-div");
  };

  render() {
    const html = (
      <>
        <div id="eventListContainerDiv" className="component-container-div">
          <div
            id="eventListContainerTitleDiv"
            className="component-title-div"
            style={{
              backgroundImage: `url('${this.props.home_page_title_jpg}')`,
            }}
          >
            <div className="component-title-text-div component-kols-title-text-div">
              <div style={{ marginBottom: '45px' }}>
                Beauty Blog
                {/* <div className="component-title-hr"></div> */}
              </div>
            </div>
            {/* <div className="component-title-text-div">
              <div>
                Beauty Blog
                <div className="component-title-hr"></div>
              </div>
            </div> */}
          </div>
          <div>
            <Row>
              {this.state.eventList.map((eventItem, index) => (
                <React.Fragment key={index}>
                  <Col md={6} lg={6} xl={3}>
                    <Link
                      // to={`/tin-tuc/${eventItem.id}`}
                      to={`/${eventItem.titleId}`}
                      className="event-item-link"
                    >
                      <img
                        alt=""
                        src={eventItem.imgURL}
                        className="event-item-background-img"
                      />
                      <div className="event-item-info-div">
                        <div className="event-item-info-title-div">
                          {eventItem.name}
                        </div>
                        <div className="event-item-info-date-div">
                          <i className="far fa-calendar-alt event-item-info-date-icon-i"></i>
                          {eventItem.createdTimeDisplayString}
                        </div>
                      </div>
                      {/* {
                                            (eventItem.status === EventStatus.ES_SCHEDULED)?(
                                                <div className='event-item-scheduled-notification-div'>
                                                    Sắp diễn ra
                                                </div>
                                            ):(<></>)
                                        } */}
                    </Link>
                  </Col>
                </React.Fragment>
              ))}
            </Row>
            {this.isReadMoreAvailable() ? (
              <Row>
                <Col>
                  <br />
                  <ReadMoreButton
                    readMoreFunction={() => {
                      this.readMoreEventList();
                    }}
                  />
                  <br />
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        </div>
      </>
    );
    return html;
  }
}
