import React from "react";
//
import main_logo_png from "../assets/images/main_logo.png";
import { OleeApiURL } from "../constants";
// import ReactGA from "react-ga";
import NavLink from "react-bootstrap/NavLink";
import ListOfNational from "../catalogs/list-of-national.json";
import ListOfDistrictCity from "../catalogs/list-of-district-city.json";

export class BaseService {
  static getLogo = () => {
    const logo = (
      <>
        <img src={main_logo_png} alt="" />
      </>
    );
    return logo;
  };

  static getNewImage = () => {
    return {
      id: null,
      name: null,
      url: null,
    };
  };

  static convertArrayStringToArray = (arrayString, character) => {
    const res = arrayString
      .split(character)
      .filter((arrayItem) => !BaseService.stringIsNullOrWhiteSpace(arrayItem));
    return res;
  };

  static convertArrayToArrayString = (array, character) => {
    let res = "";
    array.forEach((arrayItem) => {
      res += `${character}${arrayItem}`;
    });
    return res;
  };

  static formatNumber = (number) => {
    let res = "";

    let numberString = number.toString();
    while (numberString.length > 3) {
      const index = numberString.length - 3;
      res = `.${numberString.substring(index)}` + res;
      numberString = numberString.substring(0, index);
    }
    res = numberString + res;

    return res;
  };

  static getImageUrlFromServer = (id, name) => {
    // chauchiminh.com:5000/api/images/42/category-background-1590600243159
    console.log(`${id}-${name}`);
    if (!id || BaseService.stringIsNullOrWhiteSpace(name)) return null;
    const url = `${OleeApiURL}/api/images/${id}/${name}`;
    return url;
  };

  static getWindowWidth = () => {
    return window.innerWidth;
  };

  static getWindowHeight = () => {
    return window.innerHeight;
  };

  static setHeightByWidthOfClass = (className) => {
    const elements = document.getElementsByClassName(className);
    if (elements && elements.length > 0) {
      const width = elements[0].clientWidth;
      if (width !== 0) {
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.height = `${width}px`;
        }
      }
    }
  };

  static setMaxHeightByClassName = (className, baseClassName) => {
    if (!baseClassName) baseClassName = className;
    const elements = document.getElementsByClassName(className);
    // console.log('className', className);
    // console.log('elements', elements);
    if (elements && elements.length > 0) {
      let maxHeight = 0;
      const baseClassElements = document.getElementsByClassName(baseClassName);
      for (let i = 0; i < baseClassElements.length; i++) {
        const element = baseClassElements[i];
        if (element.clientHeight > maxHeight) {
          maxHeight = element.clientHeight;
        }
      }

      for (let i = 0; i < elements.length; i++) {
        elements[i].style.minHeight = `${maxHeight}px`;
        // elements[i].style.maxHeight = `${maxHeight}px`;
      }
    }
  };

  static setMaxWidthByClassName = (className, baseClassName) => {
    if (!baseClassName) baseClassName = className;
    const elements = document.getElementsByClassName(className);
    // console.log('className', className);
    // console.log('elements', elements);
    if (elements && elements.length > 0) {
      let maxWidth = 0;
      const baseClassElements = document.getElementsByClassName(baseClassName);
      for (let i = 0; i < baseClassElements.length; i++) {
        const element = baseClassElements[i];
        if (element.clientWidth > maxWidth) {
          maxWidth = element.clientWidth;
        }
      }

      for (let i = 0; i < elements.length; i++) {
        elements[i].style.minWidth = `${maxWidth}px`;
        // elements[i].style.maxHeight = `${maxHeight}px`;
      }
    }
  };

  static jumpToTopLayout = () => {
    const html = document.getElementsByTagName("html");
    if (html && html.length > 0) {
      html[0].style.scrollBehavior = "auto";
      document.documentElement.scrollTop = 0;
      html[0].style.scrollBehavior = "smooth";
    }
  };

  static setImageSliderContainerDiv = (size) => {
    const imgSliderHeight = window.innerHeight * (size ? size : 0.5);
    document.getElementById("imageSliderContainerDiv").style.height =
      imgSliderHeight + "px";
  };

  static setUserLayoutContentContainerDiv = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 768) {
      const windowHeight = window.innerHeight;
      const userLayoutHeadContainerDivHeight = document.getElementById(
        "userLayoutHeaderContainerDiv"
      ).offsetHeight;
      const userLayoutFooterContainerDivHeight = document.getElementById(
        "userLayoutFooterContainerDiv"
      ).offsetHeight;
      console.log(
        `${windowHeight} - ${userLayoutHeadContainerDivHeight} - ${userLayoutFooterContainerDivHeight}`
      );
      const headFootHeight =
        userLayoutHeadContainerDivHeight + userLayoutFooterContainerDivHeight;
      document.getElementById("userLayoutContentDiv").style.minHeight =
        window.innerHeight - headFootHeight + "px";
    }
  };

  static addClassToElement = (targetId, addClassName, time) => {
    const targetItem = document.getElementById(targetId);
    if (targetItem) {
      let timeout = time ? time : 0;
      setTimeout(() => {
        targetItem.classList.add(addClassName);
      }, timeout);
    }
  };

  static addClassToClassItems = (targetClassName, addClassName, time) => {
    const targetClassItems = document.getElementsByClassName(targetClassName);
    if (targetClassItems.length > 0) {
      let timeout = time ? time : 500;
      for (let i = 0; i < targetClassItems.length; i++) {
        // console.log('addClassToClassItems', timeout);
        setTimeout(() => {
          if (targetClassItems[i]) {
            targetClassItems[i].classList.add(addClassName);
          }
        }, timeout * i);
      }
    }
  };

  static checkScrollToElement = (
    scrollBarPosition,
    elementId,
    targetPosition
  ) => {
    let result = false;
    const element = document.getElementById(elementId);
    if (element) {
      targetPosition = targetPosition ? targetPosition : 0;
      if (scrollBarPosition > element.offsetTop + targetPosition) {
        result = true;
      }
      // if(top > element.offsetTop - position ||
      //     bottom >= element.offsetTop) {
      //     result = true;
      // }
      // const windowHeight = BaseService.getWindowHeight();
      // // if(windowHeight > ((element.offsetTop + element.offsetHeight) / 1.5)) {
      // if(windowHeight > element.offsetTop) {
      //     result = true;
      // }
    }
    return result;
  };
  static getReadMoreElement = (id, content) => {
    const element = (
      <>
        <div
          id={id}
          className="read-more-container-div"
          style={{
            maxHeight: "300px",
          }}
        >
          <div className="read-more-content-div">{content}</div>
          <div
            id={`${id}ButtonDiv`}
            className="read-more-button-div"
            onClick={() => {
              const element = document.getElementById(id);
              if (element) {
                const offsetHeight = element.scrollHeight;
                element.style.maxHeight = `${offsetHeight}px`;
                document.getElementById(`${id}ButtonDiv`).style.display =
                  "none";
              }
            }}
          >
            <i className="fas fa-sort-down"></i>
            Xem thêm
          </div>
        </div>
      </>
    );
    return element;
  };

  static timers = [];
  static setTimmer = (timerId, func, time) => {
    if (!time) time = 500;
    let idx = BaseService.timers.findIndex((t) => t.id === timerId);
    if (idx === -1) idx = BaseService.timers.length;
    const timer = {
      id: timerId,
      func: setTimeout(() => {
        func();
      }, time),
    };
    BaseService.timers[idx] = timer;
  };

  static clearTimer = (timerId) => {
    const idx = BaseService.timers.findIndex((t) => t.id === timerId);
    if (idx !== -1) {
      clearTimeout(BaseService.timers[idx].func);
    }
  };

  static getRandomString = (length) => {
    if (!length) length = 5;
    return new Array(length).join().replace(/(.|$)/g, function () {
      return ((Math.random() * 36) | 0)
        .toString(36)[Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    });
  };

  static addClickFunctionToClass = (className, clickFunction) => {
    let classList = document.getElementsByClassName(className);
    for (var i = 0; i < classList.length; i++) {
      var classItem = classList[i];
      classItem.onclick = clickFunction;
    }
  };

  static isVideoFile = (fileNameOrUrl) => {
    let result = false;
    const idx = fileNameOrUrl.indexOf(".mp4");
    if (idx !== -1) {
      result = true;
    }
    return result;
  };

  static getHeightByRatio = (originWidth, originHeight) => {
    // let windowWidth = BaseService.getWindowWidth();
    // if(windowWidth >= 1368) {
    //     windowWidth = 1368;
    // }
    const imageSliderContainerDiv = document.getElementById(
      "imageSliderContainerDiv"
    );
    let windowWidth = imageSliderContainerDiv.clientWidth;
    return (windowWidth * originHeight) / originWidth;
  };

  static stringIsNullOrWhiteSpace = (str) => {
    // return str === null || str.match(/^[\s\n\r]*$/) !== null;
    return !str || !str.trim();
  };

  static setBodyScroll = (scrollEnable) => {
    const body = document.getElementById("body");
    if (scrollEnable) {
      body.classList.remove("body-noscroll");
    } else {
      body.classList.add("body-noscroll");
    }
  };

  static isObjectHasKey = (object, key) => {
    const result = object.hasOwnProperty(key);
    return result;
  };

  static getDateTimeString = () => {
    const result = new Date().getTime();
    return result;
  };

  static setDeleteConfirmationModalDetail = (type, name) => {
    return {
      title: "Xác nhận xóa",
      content: (
        <>
          <div>
            Bạn có chắc muốn xóa {type}{" "}
            <span style={{ fontWeight: "bold" }}>{name}</span>
          </div>
          <div>Dữ liệu sau khi xóa sẽ không thể phục hồi được.</div>
        </>
      ),
    };
  };

  static transformStringToJustAlphabetAndNumber = (str) => {
    let res = "";
    const match = " abcdefghijklmnopqrstuvwxyz0123456789";
    str.split("").forEach((t) => {
      if (match.indexOf(t) !== -1) {
        res += t;
      }
    });
    res = res.replace(/\s\s+/g, " ");
    return res;
  };

  static transformVNIToEnglishCase = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/Đ/g, "D");
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "");
    str = str.replace(/\u02C6|\u0306|\u031B/g, "");
    return str;
  };

  static transformVNIToHyphenCase = (str) => {
    if (BaseService.stringIsNullOrWhiteSpace(str)) return "";
    str = BaseService.transformStringToJustAlphabetAndNumber(
      BaseService.transformVNIToEnglishCase(str).trim().toLowerCase()
    ).replace(/\s/g, "-");
    return str;
  };

  static transformNumberToString = (number, stringLength) => {
    if (!stringLength) stringLength = 9;
    let res = number.toString();
    while (res.length < stringLength) {
      res = "0" + res;
    }
    return res;
  };

  static getOrderListWithName = (originList) => {
    let res = originList;
    if (res.length > 0) {
      const orderList = originList.map((t) => {
        return {
          id: t.id,
          name: BaseService.stringIsNullOrWhiteSpace(t.name) ? "" : t.name,
          orderNumber: BaseService.transformNumberToString(
            t.orderNumber ? t.orderNumber : 0
          ),
        };
      });
      orderList.sort(function (a, b) {
        return (
          a.orderNumber.toString().localeCompare(b.orderNumber.toString()) ||
          a.name.toString().localeCompare(b.name.toString())
        );
      });
      res = [];
      orderList.forEach((t) => {
        res.push(originList.find((fT) => fT.id === t.id));
      });
    }
    return res;
  };

  static getOrderListWithText = (originList) => {
    let res = originList;
    if (res.length > 0) {
      const orderList = originList.map((t) => {
        return {
          id: t.id,
          text: BaseService.stringIsNullOrWhiteSpace(t.text) ? "" : t.text,
          orderNumber: BaseService.transformNumberToString(
            t.orderNumber ? t.orderNumber : 0
          ),
        };
      });
      orderList.sort(function (a, b) {
        return (
          a.orderNumber.toString().localeCompare(b.orderNumber.toString()) ||
          a.text.toString().localeCompare(b.text.toString())
        );
      });
      res = [];
      orderList.forEach((t) => {
        res.push(originList.find((fT) => fT.id === t.id));
      });
    }
    return res;
  };

  static getOrderListWithCreatedTime = (originList) => {
    let res = originList;
    if (res.length > 0) {
      const orderList = originList.map((t) => {
        return {
          id: t.id,
          createdTimeOrderString: BaseService.stringIsNullOrWhiteSpace(
            t.createdTimeOrderString
          )
            ? ""
            : t.createdTimeOrderString,
          orderNumber: BaseService.transformNumberToString(
            t.orderNumber ? t.orderNumber : 0
          ),
        };
      });
      orderList.sort(function (a, b) {
        return (
          a.orderNumber.toString().localeCompare(b.orderNumber.toString()) ||
          b.createdTimeOrderString
            .toString()
            .localeCompare(a.createdTimeOrderString.toString())
        );
      });
      res = [];
      orderList.forEach((t) => {
        res.push(originList.find((fT) => fT.id === t.id));
      });
    }
    return res;
  };

  static convertNumberTo2CharString = (number) => {
    const res = number > 9 ? number : `0${number}`;
    return res;
  };

  static convertCreatedTimeToDateString = (createdTime) => {
    const date = new Date(createdTime);
    const res = `${BaseService.convertNumberTo2CharString(
      date.getDate()
    )}/${BaseService.convertNumberTo2CharString(
      date.getMonth() + 1
    )}/${date.getFullYear()} ${BaseService.convertNumberTo2CharString(
      date.getHours()
    )}:${BaseService.convertNumberTo2CharString(date.getMinutes())}`;
    return res;
  };

  static convertCreatedTimeToDateOrderString = (createdTime) => {
    const date = new Date(createdTime);
    const res = `${date.getFullYear()}${BaseService.convertNumberTo2CharString(
      date.getMonth() + 1
    )}${BaseService.convertNumberTo2CharString(
      date.getDate()
    )}:${BaseService.convertNumberTo2CharString(
      date.getHours()
    )}${BaseService.convertNumberTo2CharString(
      date.getMinutes()
    )}${BaseService.convertNumberTo2CharString(date.getSeconds())}`;
    return res;
  };

  static compareAreStringsDifferent = (string1, string2) => {
    let isDifferent = false;
    string1 = !BaseService.stringIsNullOrWhiteSpace(string1) ? string1 : "";
    string2 = !BaseService.stringIsNullOrWhiteSpace(string2) ? string2 : "";
    if (string1 !== string2) {
      isDifferent = true;
    }
    return isDifferent;
  };

  static compareAreImagesDifferent = (image1, image2) => {
    let isDifferent = false;
    if (
      (image1 && image1.id && !image2) ||
      (!image1 && image2 && image2.id) ||
      (image1 && image2 && image1.id !== image2.id)
    ) {
      isDifferent = true;
    }
    return isDifferent;
  };

  static copyAnObject = (object) => {
    return JSON.parse(JSON.stringify(object));
  };

  static getTimeStamp = (date) => {
    date = date ? date : new Date();
    return date.getTime();
  };

  static getInitialColorList = () => {
    return [
      {
        id: -1,
        name: "Mặc định",
        colorCode: null,
        orderNumber: -1,
      },
    ];
  };

  static getRawTextFromHTML = (html) => {
    let div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText;
  };

  static getTimeCountdown = (startDate, diffDate) => {
    const now = new Date();
    let endDate = new Date(`${startDate} 00:00:00`);
    if (endDate < now) {
      endDate = new Date(`${startDate} 23:59:59`);
      endDate = BaseService.addDays(endDate, -1);
      while (endDate < now) {
        endDate = BaseService.addDays(endDate, diffDate);
      }
    } else {
      endDate = new Date(`${startDate} 23:59:59`);
    }

    // let endDate = new Date(`${startDate} 23:59:59`);
    //
    // while (endDate < now) {
    //   endDate = BaseService.addDays(endDate, diffDate - 1);
    //   endDate.setHours(23, 59, 59, 999);
    // }

    const endDateTime = endDate.getTime();
    const nowDateTime = now.getTime();
    const countMiliseconds = endDateTime - nowDateTime;
    const seconds = countMiliseconds / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    // alert(`${days}D, ${hours}:${minutes}:${seconds}`);
    const countdownInfo = {
      seconds: Math.floor(seconds % 60),
      minutes: Math.floor(minutes % 60),
      hours: Math.floor(hours % 24),
      days: Math.floor(days),
    };
    // alert(JSON.stringify(countdownInfo));
    return countdownInfo;
  };

  static addDays = (date, days) => {
    const res = new Date(date.setDate(date.getDate() + days));
    return res;
  };

  static setLocalStorage = (name, value, expiryDays, callBack) => {
    localStorage.setItem(name, value);
    setTimeout(() => {
      if (callBack) callBack();
    }, 10);
  };

  static getLocalStorage = (name) => {
    return localStorage.getItem(name);
  };

  static setCookie = (name, value, expiryDays, callBack) => {
    let date = new Date();
    date.setTime(date.getTime() + expiryDays * 24 * 60 * 60 * 1000);
    let expires = date.toUTCString();
    document.cookie = `${name}=${value}; expires=${expires}; path=/;`;
    setTimeout(() => {
      if (callBack) callBack();
    }, 10);
  };

  static removeCookie = (name, callBack) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    setTimeout(() => {
      if (callBack) callBack();
    }, 10);
  };

  static getCookie = (name) => {
    name += "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  static getRecordStatus = (recordStatusType) => {
    switch (recordStatusType) {
      case 0: {
        return "RECORD_STATUS_0_NEW";
      }
      case 1: {
        return "RECORD_STATUS_1_READ";
      }
      default: {
      }
    }
  };

  static preLoadImage = (imageURL) => {
    let cache = document.createElement("CACHE");
    cache.style = "position:absolute;z-index:-1000;opacity:0;display:none;";
    document.body.appendChild(cache);
    let image = new Image();
    image.src = imageURL;
    image.style = "position:absolute";
    cache.appendChild(image);
  };

  static setGAPageView = (pathName) => {
    // ReactGA.pageview(pathName);
  };

  static setGASendSaleRequestsEvent = (type, requestId) => {
    // ReactGA.event({
    //   category: "Product",
    //   action: type,
    //   value: requestId,
    // });
  };

  static convertNavMenuListItem = (adminMenuListItem) => {
    return {
      id: adminMenuListItem.id,
      title: adminMenuListItem.text,
      url: adminMenuListItem.path,
      subItems: [],
    };
  };

  static convertNavMenuList = (adminMenuList, parentId) => {
    let navMenuList = [];
    if (BaseService.stringIsNullOrWhiteSpace(parentId)) {
      adminMenuList
        .filter((t) => BaseService.stringIsNullOrWhiteSpace(t.parentId))
        .forEach((t) => {
          const navMenuListItem = BaseService.convertNavMenuListItem(t);
          navMenuListItem.subItems = BaseService.convertNavMenuList(
            adminMenuList,
            navMenuListItem.id
          );
          navMenuList.push(navMenuListItem);
        });
    } else {
      adminMenuList
        .filter((t) => t.parentId === parentId)
        .forEach((t) => {
          const navMenuListItem = BaseService.convertNavMenuListItem(t);
          navMenuListItem.subItems = BaseService.convertNavMenuList(
            adminMenuList,
            navMenuListItem.id
          );
          navMenuList.push(navMenuListItem);
        });
    }
    return navMenuList;
  };

  static getMenuNavLink = (menuItem) => {
    const url = menuItem.url;
    const isAExternalLink = url.includes("http:") || url.includes("https:");
    const element = (
      <>
        {!isAExternalLink ? (
          <NavLink to={menuItem.url}>{menuItem.title}</NavLink>
        ) : (
          <a href={menuItem.url}>{menuItem.title}</a>
        )}
      </>
    );
    return element;
  };

  static getImageLink = (url) => {
    return `https://lh3.google.com/u/0/d/${url}`;
  }

  static getNationalDistrictAndCity = () => {
    const list = [...ListOfNational].concat([...ListOfDistrictCity]);
    return list;
  }
}
