import React from "react";
import MetaTags from "react-meta-tags";
import { connect } from "react-redux";
import "./app.css";
//
import "bootstrap/dist/css/bootstrap.min.css";
import "suneditor/dist/css/suneditor.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WebUserLayoutContainer from "../containers/web-user-layout-container";
import AdminLayoutContainer from "../containers/admin-layout-container";
import HomePageContainer from "../containers/home-page-container";
import LoginPageContainer from "../containers/login-page-container";
// import BannerGroupPageContainer from '../containers/banner-group-page-container';
import AdminProductPageContainer from "../containers/admin-product-page-container";
import AdminTypicalFacePageContainer from "../containers/admin-typical-face-page-container";
import AdminBannerPageContainer from "../containers/admin-banner-container";
import AdminVideoPageContainer from "../containers/admin-video-container";
import AdminNewsPageContainer from "../containers/admin-news-container";
import AdminHelperPageContainer from "../containers/admin-helper-container";
import ProductPageContainer from "../containers/product-page-container";
import ProductDetailContainer from "../containers/product-detail-container";
import NewsDetailContainer from "../containers/news-detail-container";
import TypicalFaceDetailContainer from "../containers/typical-face-detail-container";
import StarFaceDetailContainer from "../containers/star-face-detail-container";
import ContactPageContainer from "../containers/contact-container";
import CartContainer from "../containers/cart-container";
import AdminAdvisoryContainer from "../containers/admin-advisory-container";
import AdminAnalyticsContainer from "../containers/admin-analytics-container";
import AdminOrderContainer from "../containers/admin-order-container";
import AdminUIConfigsContainer from "../containers/admin-ui-configs-container";
import { BaseService } from "../services/base-service";
import {
  categoryPageActions,
  uiConfigsPageActions,
  webUserLayoutActions,
} from "../redux/actions";
import {
  getUIConfigsListRequestResult,
  getUIConfigsListRequestStatus,
} from "../redux/selectors/ui-configs-page-selectors";
import { RequestStatus } from "../constants";
import { LoadingComponent } from "./public/loading-component";
import { Container, Row, Col } from "react-bootstrap";
import {
  categoryPageSelectors,
  webUserLayoutSelectors,
} from "../redux/selectors";
// import ReactGA from "react-ga";

const mapStateToProps = (state, ownProps) => {
  return {
    uiConfigsListRequestStatus: getUIConfigsListRequestStatus(state),
    uiConfigsListRequestResult: getUIConfigsListRequestResult(state),
    categoryListRequestStatus:
      categoryPageSelectors.getCategoryListRequestStatus(state),
    categoryList: categoryPageSelectors.getCategoryList(state),
    getAllSlugsRequestStatus:
      webUserLayoutSelectors.getGetAllSlugsRequestStatus(state),
    getAllSlugsRequestResult:
      webUserLayoutSelectors.getGetAllSlugsRequestResult(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUIConfigsList: () => {
    dispatch(uiConfigsPageActions.getUIConfigsList());
  },

  getCategoryList: () => {
    dispatch(categoryPageActions.getCategoryList());
  },

  getAllSlugs: () => {
    dispatch(webUserLayoutActions.getAllSlugs());
  },
});

class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      windowWidth: 0,
      webUserPaths: [
        {
          path: "/",
          htmlRender: () => {
            BaseService.setGAPageView("/trang-chu");
            return this.getWebUserLayout(
              <HomePageContainer windowWidth={this.state.windowWidth} />
            );
          },
        },
        {
          path: "/trang-chu",
          htmlRender: () => {
            BaseService.setGAPageView("/trang-chu");
            return this.getWebUserLayout(
              <HomePageContainer windowWidth={this.state.windowWidth} />
            );
          },
        },
        {
          path: "/danh-sach-san-pham",
          htmlRender: () => {
            BaseService.setGAPageView("/danh-sach-san-pham");
            return this.getWebUserLayout(
              <ProductPageContainer windowWidth={this.state.windowWidth} />
            );
          },
        },
        // {
        //   path: "/san-pham/:brandname/:titleid",
        //   htmlRender: () => {
        //     return this.getWebUserLayout(
        //       <ProductDetailContainer windowWidth={this.state.windowWidth} />
        //     );
        //   },
        // },
        {
          path: "/gio-hang",
          htmlRender: () => {
            BaseService.setGAPageView("/gio-hang");
            return this.getWebUserLayout(
              <CartContainer windowWidth={this.state.windowWidth} />
            );
          },
        },
        // {
        //   path: "/tin-tuc/:newsid",
        //   htmlRender: () => {
        //     return this.getWebUserLayout(
        //       <NewsDetailContainer windowWidth={this.state.windowWidth} />
        //     );
        //   },
        // },
        {
          path: "/nha-phan-phoi/:typicalfaceid",
          htmlRender: () => {
            return this.getWebUserLayout(
              <TypicalFaceDetailContainer
                windowWidth={this.state.windowWidth}
              />
            );
          },
        },
        {
          path: "/guong-mat-nghe-si/:typicalfaceid",
          htmlRender: () => {
            return this.getWebUserLayout(
              <StarFaceDetailContainer windowWidth={this.state.windowWidth} />
            );
          },
        },
        {
          path: "/lien-he",
          htmlRender: () => {
            return this.getWebUserLayout(
              <ContactPageContainer windowWidth={this.state.windowWidth} />
            );
          },
        },
        {
          path: "/health-check",
          htmlRender: () => {
            BaseService.setGAPageView("/health-check");
            return <div>Health Check</div>;
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.props.getUIConfigsList();
    this.props.getCategoryList();
    this.props.getAllSlugs();
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    // ReactGA.initialize("UA-186979776-1");
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.uiConfigsListRequestStatus !==
      nextProps.uiConfigsListRequestStatus &&
      nextProps.uiConfigsListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      const uiConfigsListRequestResult =
        nextProps.uiConfigsListRequestResult.filter(
          (t) => t.type === "UI_CONFIGS_2_PAGES" && t.isDeleted !== true
        );
      const webUserPaths = this.state.webUserPaths;
      uiConfigsListRequestResult.forEach((t) => {
        const content = JSON.parse(t.content);
        const path = {
          path: content.url,
          htmlRender: () => {
            return this.getWebUserLayout(
              <Container className="app-dynamic-page-container-div">
                <Row>
                  <Col>
                    <div
                      className="app-html-content-wrapper-div"
                      dangerouslySetInnerHTML={{
                        __html: content.html,
                      }}
                    ></div>
                  </Col>
                </Row>
              </Container>
            );
          },
        };
        webUserPaths.push(path);
      });
      this.setState({
        webUserPaths,
      });
    }

    // if (
    //   this.props.categoryListRequestStatus !==
    //     nextProps.categoryListRequestStatus &&
    //   nextProps.categoryListRequestStatus === RequestStatus.RQ_SUCCESS
    // ) {
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  getWebUserLayoutBySlug() {
    let layout = <></>;
    let path = window.location.pathname;
    if (
      !BaseService.stringIsNullOrWhiteSpace(path) &&
      path.lastIndexOf("/") === 0
    ) {
      path = path.substring(1);
      const slug = this.props.getAllSlugsRequestResult[path];
      if (!BaseService.stringIsNullOrWhiteSpace(path)) {
        switch (slug) {
          case "product": {
            layout = (
              <>
                <MetaTags>
                  <title>
                    Mỹ Phẩm Souhi - myphamsouhi.com
                  </title>
                </MetaTags>
                <WebUserLayoutContainer
                  windowWidth={this.state.windowWidth}
                  bodyContent={
                    <ProductDetailContainer
                      windowWidth={this.state.windowWidth}
                    />
                  }
                />
              </>
            );
            break;
          }
          case "news": {
            layout = (
              <>
                <MetaTags>
                  <title>
                    Mỹ Phẩm Souhi - myphamsouhi.com
                  </title>
                </MetaTags>
                <WebUserLayoutContainer
                  windowWidth={this.state.windowWidth}
                  bodyContent={
                    <NewsDetailContainer windowWidth={this.state.windowWidth} />
                  }
                />
              </>
            );
            break;
          }
          default: {
          }
        }
      } else {
        layout = (
          <>
            <MetaTags>
              <title>
                Mỹ Phẩm Souhi - myphamsouhi.com
              </title>
            </MetaTags>
            <WebUserLayoutContainer
              windowWidth={this.state.windowWidth}
              bodyContent={
                <HomePageContainer windowWidth={this.state.windowWidth} />
              }
            />
          </>
        );
      }
    }
    return layout;
  }

  getWebUserLayout(bodyContent) {
    const layout = (
      <>
        <MetaTags>
          <title>
            Mỹ Phẩm Souhi - myphamsouhi.com
          </title>
        </MetaTags>
        <WebUserLayoutContainer
          windowWidth={this.state.windowWidth}
          bodyContent={bodyContent}
        />
      </>
    );
    return layout;
  }

  getAdminLayout(bodyTitle, bodyContent) {
    const layout = (
      <>
        <MetaTags>
          <title>Souhi Việt Nam - Quản Trị</title>
        </MetaTags>
        <AdminLayoutContainer
          windowWidth={this.state.windowWidth}
          bodyTitle={bodyTitle}
          bodyContent={bodyContent}
        />
      </>
    );
    return layout;
  }

  handleResize = () => {
    // console.log(BaseService.getWindowWidth());
    this.setState({
      windowWidth: BaseService.getWindowWidth(),
    });
  };

  renderWebUserRouter = () => {
    const element = (
      <>
        {this.props.uiConfigsListRequestStatus === RequestStatus.RQ_REQUEST ? (
          <LoadingComponent />
        ) : (
          this.state.webUserPaths.map((router) => (
            <Route exact path={router.path} render={router.htmlRender}></Route>
          ))
        )}
      </>
    );
    return element;
  };

  appRouter = () => {
    return (
      <>
        {/* WebUser */}
        {this.renderWebUserRouter()}
        {/* <Route exact path="/">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '30px', flexDirection: 'column', textAlign: 'center', fontSize: '24px', lineHeight: '45px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '150px', height: '150px', borderRadius: '50%', marginTop: '45px', marginBottom: '15px', border: '12px solid #7F35B2', opacity: '.5' }}>
              <i class="fas fa-truck-loading" style={{ fontSize: '60px', position: 'absolute', color: '#7F35B2' }}></i>
            </div>
            Chúng tôi đang xây dựng nội dung trang web<br />
            Xin vui lòng quay lại sau.<br />
            Xin cảm ơn!
          </div>
        </Route> */}
        {/* <Route exact path="/test">
          {this.getWebUserLayout(<HomePageContainer windowWidth={this.state.windowWidth} />)}
        </Route> */}

        {/* Admin */}
        <Route exact path="/quan-tri">
          {this.getAdminLayout(null)}
        </Route>
        <Route exact path="/quan-tri/giao-dien">
          {this.getAdminLayout(
            "Chỉnh sửa giao diện",
            <AdminUIConfigsContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/tong-quan">
          {this.getAdminLayout(null)}
        </Route>
        <Route exact path="/quan-tri/thong-ke">
          {this.getAdminLayout(
            "Thống kê",
            <AdminAnalyticsContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/yeu-cau-tu-van">
          {this.getAdminLayout(
            "Yêu cầu tư vấn",
            <AdminAdvisoryContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/don-dat-hang">
          {this.getAdminLayout(
            "Đơn đặt hàng",
            <AdminOrderContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/menu">
          {this.getAdminLayout(null)}
        </Route>
        <Route exact path="/quan-tri/banner">
          {this.getAdminLayout(
            "Banner",
            <AdminBannerPageContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/dich-vu">
          {this.getAdminLayout(null)}
        </Route>
        <Route exact path="/quan-tri/san-pham">
          {this.getAdminLayout(
            "Sản phẩm",
            <AdminProductPageContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/chinh-sach">
          {this.getAdminLayout(null)}
        </Route>
        <Route exact path="/quan-tri/guong-mat-tieu-bieu">
          {this.getAdminLayout(
            "Gương mặt tiêu biểu",
            <AdminTypicalFacePageContainer
              windowWidth={this.state.windowWidth}
            />
          )}
        </Route>
        <Route exact path="/quan-tri/video">
          {this.getAdminLayout(
            "Video",
            <AdminVideoPageContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/tin-tuc">
          {this.getAdminLayout(
            "Beauty Blog",
            <AdminNewsPageContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/ho-tro">
          {this.getAdminLayout(
            "Hổ Trợ",
            <AdminHelperPageContainer windowWidth={this.state.windowWidth} />
          )}
        </Route>
        <Route exact path="/quan-tri/bottom">
          {this.getAdminLayout(null)}
        </Route>
        <Route exact path="/quan-tri/he-thong">
          {this.getAdminLayout(null)}
        </Route>

        {/* Other */}
        <Route exact path="/dang-nhap">
          <LoginPageContainer />
        </Route>

        {/* Special Slugs */}
        <Route
          exact
          path="/:titleid"
          render={() => {
            return this.getWebUserLayoutBySlug();
          }}
        ></Route>
      </>
    );
  };

  render() {
    const html =
      this.props.getAllSlugsRequestStatus === RequestStatus.RQ_SUCCESS ? (
        <>
          {/* <Router>
            <Switch>
              {this.appRouter()}
            </Switch>
          </Router> */}
        </>
      ) : (
        <></>
      );
    return html;
  }
}

export const App = connect(mapStateToProps, mapDispatchToProps)(AppContainer);
