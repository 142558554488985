import React from "react";
import "./admin-product-detail.css";
//
import { withRouter } from "react-router";
import { withFormik } from "formik";
import { Row, Col, Tabs, Tab } from "react-bootstrap";
import {
  FormTextInput,
  FormTextAreaInput,
  FormImageUploader,
  FormImageListUploader,
  FormButton,
  FormSelect,
  FormMultiSelectBox,
  FormCheckbox,
  FormSunEditor,
  // FormCKEditor,
  FormHashTagSelector,
  FormColorList,
  FormNumberInput,
} from "../public/form-components";
import { BaseService } from "../../services/base-service";
import { RequestStatus } from "../../constants";
import { ProductService } from "../../services/product-service";

class FormikAdminProductDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      originProduct: this.props.product,
      productNameTimerId: BaseService.getRandomString(),
      categoryList: BaseService.getOrderListWithName(
        this.props.categoryList
      ).map((categoryItem) => {
        return {
          text: categoryItem.name,
          value: categoryItem.id,
        };
      }),
    };
  }

  componentDidMount() {
    if (this.props.getAllHashTags) {
      this.props.getAllHashTags();
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "FormikAdminProductDetail - componentWillReceiveProps - nextProps",
      nextProps
    );
  }

  trackIsDataHasUpdatedTimeout = null;
  trackIsDataHasUpdated = () => {
    clearTimeout(this.trackIsDataHasUpdatedTimeout);
    this.trackIsDataHasUpdatedTimeout = setTimeout(() => {
      let isDataHasUpdated = true;
      this.props.trackIsDataHasUpdated(isDataHasUpdated);
    }, 100);
  };

  changeProductName = (value) => {
    const titleId = BaseService.transformVNIToHyphenCase(value);
    this.props.setFieldValue("product.name", value);
    this.props.setFieldValue("product.titleId", titleId);
    this.trackIsDataHasUpdated();
    BaseService.clearTimer(this.state.productNameTimerId);
    BaseService.setTimmer(this.state.productNameTimerId, () => {
      // console.log(value);
      this.props.checkTitleIsAvailable(titleId);
    });
  };

  blurProductName = (e) => {
    this.props.setFieldTouched("errorProductName", true);
  };

  changeProductTitleId = (value) => {
    this.props.setFieldValue("product.titleId", value);
    this.trackIsDataHasUpdated();
    BaseService.clearTimer(this.state.productNameTimerId);
    BaseService.setTimmer(this.state.productNameTimerId, () => {
      // console.log(value);
      this.props.checkTitleIsAvailable(value);
    });
  };

  blurProductTitleId = (e) => {
    this.props.setFieldTouched("errorProductTitleId", true);
  };

  // changeProductDisplayName = (value) => {
  //   let styleConfig = this.props.values.product.styleConfig;
  //   if (!styleConfig) styleConfig = {};
  //   const originStyleConfig = { ...styleConfig };
  //   styleConfig.displayName = value;
  //   this.props.setFieldValue("product.styleConfig", styleConfig);
  //   if (value !== originStyleConfig.displayName) {
  //     this.trackIsDataHasUpdated();
  //   }
  // };

  // blurProductDisplayName = () => {
  //   this.props.setFieldTouched("errorProductDisplayName", true);
  // };

  changeProductDescription = (value) => {
    this.props.setFieldValue("product.description", value);
    if (value !== this.props.product.description) {
      this.trackIsDataHasUpdated();
    }
  };

  changeCategory = (value) => {
    this.props.setFieldValue("product.category.id", value);
    this.props.setFieldValue("category_selected_value", value);
    //
    let saleInfo = this.props.values.product.saleInfo;
    if (!saleInfo) saleInfo = {};
    if (!saleInfo.subCategories) {
      saleInfo.subCategories = [];
    }
    const idx = saleInfo.subCategories.findIndex((t) => +t.value === +value);
    if (idx !== -1) {
      saleInfo.subCategories.splice(idx, 1);
    }
    // alert(JSON.stringify(saleInfo.subCategories));
    this.props.setFieldValue("product.saleInfo", saleInfo);
    //
    this.trackIsDataHasUpdated();
  };

  changeCategories = (category) => {
    let saleInfo = this.props.values.product.saleInfo;
    if (!saleInfo) saleInfo = {};
    if (!saleInfo.subCategories) {
      saleInfo.subCategories = [];
    }
    const idx = saleInfo.subCategories.findIndex(
      (t) => +t.value === +category.value
    );
    if (idx === -1) {
      saleInfo.subCategories.push(category);
    } else {
      saleInfo.subCategories.splice(idx, 1);
    }
    // alert(JSON.stringify(saleInfo.subCategories));
    this.props.setFieldValue("product.saleInfo", saleInfo);
    this.trackIsDataHasUpdated();
  };

  changeProductPrice = (value) => {
    this.props.setFieldValue("product.price", value);
    this.trackIsDataHasUpdated();
  };

  changeProductOriginalPrice = (value) => {
    this.props.setFieldValue("product.originalPrice", value);
    this.trackIsDataHasUpdated();
  };

  changeProductDiscountPercent = (value) => {
    this.props.setFieldValue("product.discountPercent", value);
    this.trackIsDataHasUpdated();
  };

  changeProductPromoText = (value) => {
    this.props.setFieldValue("product.promoText", value);
    this.trackIsDataHasUpdated();
  };

  changeInformationDescription = (value) => {
    this.props.setFieldValue("product.informationDescription", value);
    if (value !== this.props.product.informationDescription) {
      this.trackIsDataHasUpdated();
    }
  };

  changeIngredientDescriptione = (value) => {
    this.props.setFieldValue("product.ingredientDescription", value);
    if (value !== this.props.product.ingredientDescription) {
      this.trackIsDataHasUpdated();
    }
  };

  changeUsesDescription = (value) => {
    this.props.setFieldValue("product.usesDescription", value);
    if (value !== this.props.product.usesDescription) {
      this.trackIsDataHasUpdated();
    }
  };

  onChangeProductBackgroundImage = (res) => {
    this.props.setFieldTouched("errorImage", true);
    const imageId = res ? res.data.id : null;
    const imageName = res ? res.data.name : null;
    let image =
      this.props.values.product.image === "none"
        ? {}
        : this.props.values.product.image;
    if (!image) image = {};
    image.id = imageId;
    image.name = imageName;
    this.props.setFieldValue("product.image", image);
    this.trackIsDataHasUpdated();
  };

  onChangeProductItemImageInput = (res, index) => {
    let images = this.props.values.product.images;

    const imageId = res ? res.data.id : null;
    const imageName = res ? res.data.name : null;
    let image = images[index];
    if (!image) {
      image = BaseService.getNewImage();
      image.colorTags = BaseService.getInitialColorList();
    }
    image.id = imageId;
    image.name = imageName;
    images[index] = image;

    this.props.setFieldValue("product.images", images);
    this.trackIsDataHasUpdated();
  };

  onUpdateProductItemImageInput = (imageItem) => {
    let images = this.props.values.product.images;
    const idx = images.findIndex((t) => t.id === imageItem.id);
    images[idx] = imageItem;
    this.props.setFieldValue("product.images", images);
    this.trackIsDataHasUpdated();
  };

  onRemoveProductItemImage = (index) => {
    let images = this.props.values.product.images;
    images.splice(index, 1);
    this.props.setFieldValue("product.images", images);
    this.trackIsDataHasUpdated();
  };

  changeProductColors = (value) => {
    this.props.setFieldValue("product.colorTags", value);
    this.trackIsDataHasUpdated();
  };

  changeProductIsNew = (value) => {
    this.props.setFieldValue("product.isNew", value);
    this.trackIsDataHasUpdated();
  };

  changeProductIsHot = (value) => {
    this.props.setFieldValue("product.isHot", value);
    this.trackIsDataHasUpdated();
  };

  changeProductSale11 = (value) => {
    let saleInfo = this.props.values.product.saleInfo;
    if (!saleInfo) saleInfo = {};
    saleInfo.sale11 = value;
    this.props.setFieldValue("product.saleInfo", saleInfo);
    this.trackIsDataHasUpdated();
  };

  changeProductSeoTitle = (value) => {
    this.props.setFieldValue("product.seoTitle", value);
    this.trackIsDataHasUpdated();
  };

  changeProductSeoKeyWords = (value) => {
    this.props.setFieldValue("product.seoKeyWords", value);
    this.trackIsDataHasUpdated();
  };

  changeProductSeoDescription = (value) => {
    this.props.setFieldValue("product.seoDescription", value);
    this.trackIsDataHasUpdated();
  };

  updateHashTag = (hashTags) => {
    this.props.setFieldValue("product.hashTags", hashTags);
    this.trackIsDataHasUpdated();
    setTimeout(() => {
      console.log("product.hashTags", this.props.values.product.hashTags);
    }, 100);
  };

  getSale11OrderNumberValue = () => {
    const sale11OrderNumber =
      this.props.values.product.saleInfo &&
      this.props.values.product.saleInfo.sale11OrderNumber
        ? this.props.values.product.saleInfo.sale11OrderNumber
        : 0;
    return sale11OrderNumber;
  };

  handleOnChangeSale11OrderNumberValue = (value) => {
    let saleInfo = this.props.values.product.saleInfo;
    if (!saleInfo) saleInfo = {};
    saleInfo.sale11OrderNumber = value;
    this.props.setFieldValue("product.saleInfo", saleInfo);
    this.trackIsDataHasUpdated();
  };

  getSale11OrderNumber = () => {
    const element = (
      <>
        {this.props.values.product.saleInfo &&
        this.props.values.product.saleInfo.sale11 === true ? (
          <>
            <Row>
              <Col lg={12}>
                {/* <label>Sắp xếp mua 1 tặng 1</label> */}
                <label>Sắp xếp khuyến mãi</label>
                <FormNumberInput
                  value={this.getSale11OrderNumberValue()}
                  min="0"
                  onChange={(value) => {
                    this.handleOnChangeSale11OrderNumberValue(value);
                  }}
                />
              </Col>
            </Row>
            <br />
          </>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  save = () => {
    if (this.props.insertProductRequestStatus === RequestStatus.RQ_REQUEST)
      return;
    // console.log('save', this.props.values.product);
    this.props.handleSubmit();
  };

  renderCategoryGroupSelects = () => {
    const categorySelectedValue =
      this.props.values.product.category &&
      this.props.values.product.category.id
        ? this.props.values.product.category.id
        : this.props.categoryList && this.props.categoryList.length > 0
        ? this.props.categoryList[0].id
        : null;
    const multiCategoryList = categorySelectedValue
      ? this.state.categoryList.filter(
          (t) => +t.value !== +categorySelectedValue
        )
      : this.state.categoryList;
    const html = (
      <>
        <Row>
          <Col lg={12}>
            <FormSelect
              labelText="Thương hiệu chính"
              options={this.state.categoryList}
              selectedValue={categorySelectedValue}
              onChange={this.changeCategory}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={12}>
            <FormMultiSelectBox
              labelText="Thương hiệu khác"
              options={multiCategoryList}
              selectedValues={
                this.props.values.product.saleInfo &&
                this.props.values.product.saleInfo.subCategories
                  ? this.props.values.product.saleInfo.subCategories
                  : []
              }
              onClickItem={(category) => {
                this.changeCategories(category);
              }}
            />
          </Col>
        </Row>
        <br />
      </>
    );
    return html;
  };

  render() {
    const html = (
      <>
        {/* <div onClick={() => {
                console.log(this.props.errors);
            }}>Check error</div> */}
        <Row>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <FormImageUploader
                  labelText="Ảnh bìa"
                  guide="(450x450, < 2MB)"
                  isRequired={true}
                  type="product-background"
                  // imgUrl={(!BaseService.stringIsNullOrWhiteSpace(this.props.values.product.image_url))?(`http://${this.props.values.product.image_url}`):(null)}
                  imgUrl={
                    this.props.values.product.image
                      ? BaseService.getImageUrlFromServer(
                          this.props.values.product.image.id,
                          this.props.values.product.image.name
                        )
                      : null
                  }
                  onChangeImageInput={this.onChangeProductBackgroundImage}
                  error={
                    this.props.touched.errorImage &&
                    this.props.errors.errorImage
                      ? this.props.errors.errorImage
                      : null
                  }
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormImageListUploader
                  labelText="Ảnh sản phẩm"
                  guide="(450x450, < 2MB)"
                  type="product-image"
                  imageList={
                    this.props.values.product.images
                      ? this.props.values.product.images
                      : []
                  }
                  // imgUrls={
                  //     (this.props.values.product.images && this.props.values.product.images.length > 0)?(
                  //         this.props.values.product.images.map(t => {
                  //             return BaseService.getImageUrlFromServer(t.id, t.name)
                  //         })
                  //     ):([])
                  // }
                  colorTags={this.props.values.product.colorTags}
                  onChangeImageInput={this.onChangeProductItemImageInput}
                  onUpdateProductItemImageInput={
                    this.onUpdateProductItemImageInput
                  }
                  onRemoveImage={this.onRemoveProductItemImage}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormColorList
                  labelText="Màu"
                  colorTags={this.props.values.product.colorTags}
                  onChange={this.changeProductColors}
                  // error={(this.props.touched.errorProductName && this.props.errors.errorProductName)?(this.props.errors.errorProductName):(null)}
                />
              </Col>
            </Row>
            <br />
          </Col>
          <Col lg={6}>
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Tên"
                  value={this.props.values.product.name}
                  onChange={this.changeProductName}
                  onBlur={this.blurProductName}
                  error={
                    this.props.touched.errorProductName &&
                    this.props.errors.errorProductName
                      ? this.props.errors.errorProductName
                      : null
                  }
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormTextInput
                  labelText="Slug sản phẩm"
                  value={this.props.values.product.titleId}
                  onChange={this.changeProductTitleId}
                  onBlur={this.blurProductTitleId}
                  error={
                    // this.props.touched.errorProductTitleId &&
                    this.props.errors.errorProductTitleId
                      ? this.props.errors.errorProductTitleId
                      : null
                  }
                  isRequired={true}
                />
              </Col>
            </Row>
            <br />
            {/* <Row>
              <Col lg={12}>
                <FormSunEditor
                  labelText="Tên hiển thị"
                  data={
                    this.props.values.product.styleConfig &&
                    this.props.values.product.styleConfig.displayName
                      ? this.props.values.product.styleConfig.displayName
                      : ""
                  }
                  onChange={this.changeProductDisplayName}
                  onBlur={this.blurProductDisplayName}
                  error={
                    this.props.touched.errorProductDisplayName &&
                    this.props.errors.errorProductDisplayName
                      ? this.props.errors.errorProductDisplayName
                      : null
                  }
                  height={"180"}
                  isRequired={true}
                />
              </Col>
            </Row>
            <br /> */}
            <Row>
              <Col lg={12} className="admin-product-detail-title-col">
                {/* <FormTextAreaInput
                                labelText='Ghi chú'
                                value={this.props.values.product.description}
                                onChange={this.changeProductDescription}
                            /> */}
                <label className="form-components-text-input-label">
                  Ghi Chú
                </label>
                <FormSunEditor
                  data={
                    this.props.values.product.description
                      ? this.props.values.product.description
                      : ""
                  }
                  onChange={this.changeProductDescription}
                  // isSimple={true}
                />
              </Col>
            </Row>
            <br />
            {this.renderCategoryGroupSelects()}
            <Row>
              <Col lg={12}>
                <FormHashTagSelector
                  labelText="Tab phân loại"
                  hashTags={this.props.values.product.hashTags}
                  updateHashTag={this.updateHashTag}
                  allHashTagsRequestStatus={this.props.allHashTagsRequestStatus}
                  allHashTags={this.props.allHashTags}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormTextInput
                  type="number"
                  labelText="Giá"
                  value={this.props.values.product.price}
                  onChange={this.changeProductPrice}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormTextInput
                  type="number"
                  labelText="Giá gốc"
                  value={this.props.values.product.originalPrice}
                  onChange={this.changeProductOriginalPrice}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormTextInput
                  type="number"
                  labelText="Phần trăm giảm giá"
                  value={this.props.values.product.discountPercent}
                  onChange={this.changeProductDiscountPercent}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormTextAreaInput
                  labelText="Nội dung khuyến mãi"
                  value={this.props.values.product.promoText}
                  onChange={this.changeProductPromoText}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormCheckbox
                  isChecked={this.props.values.product.isNew}
                  checkboxLabel="Là sản phẩm mới"
                  onChange={this.changeProductIsNew}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormCheckbox
                  isChecked={this.props.values.product.isHot}
                  checkboxLabel="Là sản phẩm nổi bật"
                  onChange={this.changeProductIsHot}
                />
              </Col>
            </Row>
            <br />
            <Row>
              <Col lg={12}>
                <FormCheckbox
                  isChecked={
                    this.props.values.product.saleInfo &&
                    this.props.values.product.saleInfo.sale11 === true
                  }
                  // checkboxLabel="Sale mua 1 tặng 1"
                  checkboxLabel="Tag khuyến mãi"
                  onChange={this.changeProductSale11}
                />
              </Col>
            </Row>
            <br />
            {this.getSale11OrderNumber()}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <div className="tabs-container-div form-input-tabs">
              <Tabs>
                <Tab
                  eventKey="informationDescription"
                  title="Thông tin sản phẩm"
                >
                  <FormSunEditor
                    data={
                      this.props.values.product.informationDescription
                        ? this.props.values.product.informationDescription
                        : ""
                    }
                    onChange={this.changeInformationDescription}
                  />
                  {/* <FormCKEditor
                                    data={(this.props.values.product.informationDescription)?(this.props.values.product.informationDescription):('')}
                                    onChange={this.changeInformationDescription}
                                /> */}
                </Tab>
                <Tab eventKey="ingredientDescription" title="Thành phần">
                  <FormSunEditor
                    data={
                      this.props.values.product.ingredientDescription
                        ? this.props.values.product.ingredientDescription
                        : ""
                    }
                    onChange={this.changeIngredientDescriptione}
                  />
                </Tab>
                <Tab eventKey="usesDescription" title="Hướng dẫn sử dụng">
                  <FormSunEditor
                    data={
                      this.props.values.product.usesDescription
                        ? this.props.values.product.usesDescription
                        : ""
                    }
                    onChange={this.changeUsesDescription}
                  />
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={6}>
            <FormTextInput
              labelText="Tiêu đề (SEO)"
              value={this.props.values.product.seoTitle}
              onChange={this.changeProductSeoTitle}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={6}>
            <FormTextAreaInput
              labelText="Từ khóa (SEO)"
              guide="(Mỗi từ bắt đầu bằng dấu #)"
              placeholder="Ví dụ: #Kem dưỡng da#Kem trị nám..."
              value={this.props.values.product.seoKeyWords}
              onChange={this.changeProductSeoKeyWords}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col lg={6}>
            <FormTextAreaInput
              labelText="Mô tả (SEO)"
              value={this.props.values.product.seoDescription}
              onChange={this.changeProductSeoDescription}
            />
          </Col>
        </Row>
        <br />
        <br />
        <Row>
          <Col lg={12}>
            <FormButton
              type="save"
              onClick={this.save}
              isRequesting={
                this.props.insertProductRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                this.props.updateProductRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
              disabled={
                this.props.insertProductRequestStatus ===
                  RequestStatus.RQ_REQUEST ||
                this.props.updateProductRequestStatus ===
                  RequestStatus.RQ_REQUEST
                  ? true
                  : false
              }
            />
          </Col>
        </Row>
      </>
    );
    return html;
  }
}

const myEnhancedForm = withFormik({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    console.log("mapPropsToValues - props", props);
    let values = {
      product: props.product,
      errorImage: null,
      errorProductName: null,
      errorProductTitleId: null,
      errorProductDisplayName: null,
      // image_id: null,
      // image_ids: props.product.images.map(t => {
      //     return t.id
      // }),
      category_selected_value:
        props.product && props.product.category
          ? props.product.category.id
          : null,
    };
    return values;
  },

  validate: (values, props) => {
    const errors = {};

    if (!values.product.image || !values.product.image.id) {
      errors.errorImage = "Chưa chọn hình sản phẩm";
    }

    if (BaseService.stringIsNullOrWhiteSpace(values.product.name)) {
      errors.errorProductName = "Tên sản phẩm không được rỗng";
    }

    if (BaseService.stringIsNullOrWhiteSpace(values.product.titleId)) {
      errors.errorProductTitleId = "Slug sản phẩm không được rỗng";
    }

    if (
      values.product.name !== props.productDetail_originName &&
      props.titleIsAvailableRequestResult === false
    ) {
      errors.errorProductTitleId = "Slug tồn tại trước đó";
    }

    // if (
    //   !values.product.styleConfig ||
    //   BaseService.stringIsNullOrWhiteSpace(
    //     values.product.styleConfig.displayName
    //   ) ||
    //   values.product.styleConfig.displayName === "<p><br></p>"
    // ) {
    //   errors.errorProductDisplayName = "Tên hiển thị không được rỗng";
    // }

    return errors;
  },

  handleSubmit: (values, { props, setSubmitting }) => {
    setSubmitting(true);
    // console.log('values', values);
    let product = ProductService.getProductInput(values.product);
    product.titleId = BaseService.stringIsNullOrWhiteSpace(
      values.product.titleId
    )
      ? BaseService.transformVNIToHyphenCase(values.product.name)
      : values.product.titleId;
    product.categoryId = values.category_selected_value
      ? values.category_selected_value
      : props.categoryList && props.categoryList.length > 0
      ? props.categoryList[0].id
      : null;

    // const product = {
    //     id: values.product.id,
    //     name: values.product.name,
    //     titleId: (BaseService.stringIsNullOrWhiteSpace(values.product.titleId))?(BaseService.transformVNIToHyphenCase(values.product.name)):(values.product.titleId),
    //     description: values.product.description,
    //     informationDescription: values.product.informationDescription,
    //     ingredientDescription: values.product.ingredientDescription,
    //     usesDescription: values.product.usesDescription,
    //     price: values.product.price,
    //     originalPrice: values.product.originalPrice,
    //     discountPercent: values.product.discountPercent,
    //     promoText: values.product.promoText,
    //     imageId: (values.product.image)?(values.product.image.id):(null),
    //     imageIds: (values.product.images)?(
    //         values.product.images.map(t => {
    //             return t.id;
    //         })
    //     ):([]),
    //     categoryId: (!BaseService.stringIsNullOrWhiteSpace(values.category_selected_value))?(values.category_selected_value):(
    //         (props.categoryList && props.categoryList.length > 0)?(props.categoryList[0].id):(null)
    //     ),
    //     isNew: (values.product.isNew)?(values.product.isNew):(false),
    //     isHot: (values.product.isHot)?(values.product.isHot):(false),
    //     hashTags: values.product.hashTags,
    //     seoTitle: values.product.seoTitle,
    //     seoKeyWords: values.product.seoKeyWords,
    //     seoDescription: values.product.seoDescription
    // }
    console.log("product", product);
    // return;
    props.save(product, values.product.images);
  },

  displayName: "FormikAdminProductDetail",
});

export const AdminProductDetail = withRouter(
  myEnhancedForm(FormikAdminProductDetail)
);
