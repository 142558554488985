import React from "react";
import "./admin-product-list.css";
//
import { RequestStatus } from "../../constants";
import { LoadingComponent } from "../public/loading-component";
import { TableComponent } from "../public/table-component";
import {
  FormButton,
  FormModal,
  FormConfirmationModal,
  FormNumberInput,
  FormToggle,
} from "../public/form-components";
import { AdminProductDetail } from "./admin-product-detail";
import { ProductService } from "../../services/product-service";
import { BaseService } from "../../services/base-service";

export class AdminProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenProductDetailModal: false,
      isOpenProductConfirmDeleteModal: false,
      modalTitle: "",
      confirmModalInfo: {
        modalTitle: "",
        modalContent: "",
      },
      targetProduct: null,
      deletingProductId: null,
      productList: [],
      productOrderNumberTimerId: BaseService.getRandomString(),
      productUpdateOrderNumberTargetId: null,
      productIsDeletedTimerId: BaseService.getRandomString(),
      productUpdateIsDeletedTargetId: null,
      //
      isDataHasUpdated: false,
      headers: [
        { title: "Sắp xếp", width: "111" },
        { title: "Kích hoạt", width: "99" },
        { title: "Tên sản phẩm" },
        // { title: 'Ghi chú' },
        { title: "Giá", width: "150" },
        { title: "Giá gốc", width: "150" },
        { title: "", width: "120" },
      ],
    };
  }

  componentDidMount() {
    this.props.getProductList();
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      "AdminProductPage - componentWillReceiveProps - nextProps",
      nextProps
    );

    if (
      this.props.productListRequestStatus !==
        nextProps.productListRequestStatus &&
      nextProps.productListRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      let productList = nextProps.productList;
      this.reOrderProductList(productList);
    }

    if (
      (this.props.insertProductRequestStatus !==
        nextProps.insertProductRequestStatus &&
        nextProps.insertProductRequestStatus === RequestStatus.RQ_SUCCESS) ||
      (this.props.updateProductRequestStatus !==
        nextProps.updateProductRequestStatus &&
        nextProps.updateProductRequestStatus === RequestStatus.RQ_SUCCESS) ||
      (this.props.deleteProductRequestStatus !==
        nextProps.deleteProductRequestStatus &&
        nextProps.deleteProductRequestStatus === RequestStatus.RQ_SUCCESS)
    ) {
      this.props.getAllSlugs();
      this.setState({
        isOpenProductDetailModal: false,
      });
      // setTimeout(() => {
      this.props.getProductList();
      this.props.getCategoryList();
      // }, 5000);
    }

    if (
      this.props.productDetailRequestStatus !==
        nextProps.productDetailRequestStatus &&
      nextProps.productDetailRequestStatus === RequestStatus.RQ_SUCCESS
    ) {
      if (nextProps.productDetail) {
        this.setState({
          targetProduct: nextProps.productDetail,
        });
      }
    }
  }

  reOrderProductList = (productList) => {
    let res = BaseService.getOrderListWithName(productList);
    this.setState({
      productList: res,
    });
  };

  getTableHeaders = () => {
    const headers = this.state.headers;
    return headers;
  };

  onChangeProductOrderNumber = (productId, value) => {
    const idx = this.state.productList.findIndex((t) => t.id === productId);
    if (idx !== -1) {
      let productList = this.state.productList;
      productList[idx].orderNumber = value;
      this.setState({
        productList: productList,
      });
      BaseService.clearTimer(this.state.productOrderNumberTimerId);
      BaseService.setTimmer(this.state.productOrderNumberTimerId, () => {
        this.setState({
          productUpdateOrderNumberTargetId: productId,
        });
        this.props.updateProduct_OrderNumber(
          productId,
          productList[idx].image ? productList[idx].image.id : null,
          value
        );
      });
    }
  };

  onChangeProductIsDeleted = (productId, value) => {
    const idx = this.state.productList.findIndex((t) => t.id === productId);
    if (idx !== -1) {
      let productList = this.state.productList;
      productList[idx].isDeleted = !value;
      this.setState({
        productList: productList,
      });
      BaseService.clearTimer(this.state.productIsDeletedTimerId);
      BaseService.setTimmer(this.state.productIsDeletedTimerId, () => {
        this.setState({
          productUpdateIsDeletedTargetId: productId,
        });
        this.props.updateProduct_IsDeleted(
          productId,
          productList[idx].image ? productList[idx].image.id : null,
          !value
        );
      });
    }
  };

  getTableRows = () => {
    const rows = [];
    this.state.productList.forEach((item) => {
      rows.push({
        columns: [
          <>
            <div>
              <FormNumberInput
                value={item.orderNumber}
                min="0"
                onChange={(value) => {
                  this.onChangeProductOrderNumber(item.id, value);
                }}
              />
              {this.props.updateProductOrderNumberRequestStatus ===
                RequestStatus.RQ_REQUEST &&
              this.state.productUpdateOrderNumberTargetId === item.id ? (
                <div className="form-component-submitting-message-div">
                  <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
                </div>
              ) : (
                <></>
              )}
            </div>
          </>,
          <>
            <FormToggle
              value={item.isDeleted ? !item.isDeleted : true}
              onChange={(value) => {
                this.onChangeProductIsDeleted(item.id, value);
              }}
            />
            {this.props.updateProductIsDeletedRequestStatus ===
              RequestStatus.RQ_REQUEST &&
            this.state.productUpdateIsDeletedTargetId === item.id ? (
              <div className="form-component-submitting-message-div">
                <i className="fas fa-spinner fa-spin"></i> Đang lưu ...
              </div>
            ) : (
              <></>
            )}
          </>,
          <>
            <div>
              <div
                className="table-component-title-link-div"
                onClick={() => {
                  this.openEditProduct(item);
                }}
              >
                {item.name}
                <div className="table-component-image-div">
                  <img
                    alt=""
                    src={
                      item.image
                        ? BaseService.getImageUrlFromServer(
                            item.image.id,
                            item.image.name
                          )
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </>,
          // <>
          //     <div>
          //         {item.description}
          //     </div>
          // </>,
          <>
            <div>{BaseService.formatNumber(item.price)}đ</div>
          </>,
          <>
            <div>{BaseService.formatNumber(item.originalPrice)}đ</div>
          </>,
          <>
            <div style={{ width: "max-content" }}>
              {/* <FormButton
                                text='Danh sách sản phẩm'
                                faIconClass='fas fa-clipboard-list'
                                isDisabled={true}
                            /> */}
              <FormButton
                type="delete"
                onClick={() => {
                  this.onClickDeleteProduct(item);
                }}
                isRequesting={
                  item.id === this.state.deletingProductId ? true : false
                }
              />
            </div>
          </>,
        ],
      });
    });
    return rows;
  };

  openAddNewProduct = () => {
    this.setState({
      modalTitle: "Thêm mới sản phẩm",
      targetProduct: ProductService.getNewProduct(),
      isOpenProductDetailModal: true,
      isDataHasUpdated: false,
    });
  };

  openEditProduct = (product) => {
    this.props.getProductDetail(product.id);
    this.setState({
      modalTitle: "Chỉnh sửa sản phẩm",
      targetProduct: null,
      isOpenProductDetailModal: true,
      isDataHasUpdated: false,
    });
  };

  onClickDeleteProduct = (product) => {
    if (this.props.deleteProductRequestStatus === RequestStatus.RQ_REQUEST)
      return;
    const confirmModalInfo = BaseService.setDeleteConfirmationModalDetail(
      "Sản phẩm",
      product.name
    );
    this.setState({
      targetProduct: product,
      isOpenProductConfirmDeleteModal: true,
      confirmModalInfo: {
        modalTitle: confirmModalInfo.title,
        modalContent: confirmModalInfo.content,
      },
    });
  };

  onConfirmYesOrNo = (res) => {
    this.setState({
      isOpenProductConfirmDeleteModal: false,
    });
    if (res) {
      this.setState({
        deletingProductId: this.state.targetProduct.id,
      });
      this.props.deleteProduct(this.state.targetProduct);
    }
  };

  closeModal = () => {
    this.setState({
      isOpenProductDetailModal: false,
    });
  };

  saveProductDetail = (product, imageList) => {
    if (product.id === null) {
      this.props.insertProductWithSaveProductImageList(product, imageList);
    } else {
      this.props.updateProductWithSaveProductImageList(product, imageList);
    }
  };

  trackIsDataHasUpdated = (isDataHasUpdated) => {
    this.setState({
      isDataHasUpdated: isDataHasUpdated,
    });
  };

  render() {
    const html = (
      <>
        {this.props.productListRequestStatus === RequestStatus.RQ_SUCCESS ? (
          <>
            <div>
              <FormButton type="add" onClick={this.openAddNewProduct} />
            </div>
            <TableComponent
              headers={this.getTableHeaders()}
              rows={this.getTableRows()}
              noItemRow={
                <td colSpan={this.state.headers.length}>Chưa có sản phẩm</td>
              }
            />
            <FormModal
              size="xl"
              isShowModal={this.state.isOpenProductDetailModal}
              modalTitle={this.state.modalTitle}
              modalContent={
                this.state.targetProduct ? (
                  <AdminProductDetail
                    save={this.saveProductDetail}
                    product={this.state.targetProduct}
                    productDetail_originName={
                      this.props.productDetail_originName
                    }
                    trackIsDataHasUpdated={this.trackIsDataHasUpdated}
                    insertProductRequestStatus={
                      this.props.insertProductRequestStatus
                    }
                    updateProductRequestStatus={
                      this.props.updateProductRequestStatus
                    }
                    categoryList={this.props.categoryList}
                    allHashTagsRequestStatus={
                      this.props.allHashTagsRequestStatus
                    }
                    allHashTags={this.props.allHashTags}
                    getAllHashTags={this.props.getAllHashTags}
                    //
                    titleIsAvailableRequestStatus={
                      this.props.titleIsAvailableRequestStatus
                    }
                    titleIsAvailableRequestResult={
                      this.props.titleIsAvailableRequestResult
                    }
                    checkTitleIsAvailable={this.props.checkTitleIsAvailable}
                  />
                ) : (
                  <div style={{ height: "450px" }}>
                    <LoadingComponent />
                  </div>
                )
              }
              isDataHasUpdated={this.state.isDataHasUpdated}
              closeModal={this.closeModal}
            />
            <FormConfirmationModal
              isShowModal={this.state.isOpenProductConfirmDeleteModal}
              modalTitle={this.state.confirmModalInfo.modalTitle}
              modalContent={this.state.confirmModalInfo.modalContent}
              okText="Xóa"
              cancelText="Hủy"
              onConfirmYesOrNo={this.onConfirmYesOrNo}
            />
          </>
        ) : (
          <>
            <LoadingComponent />
          </>
        )}
      </>
    );
    return html;
  }
}
