import React from "react";
import "./footer.css";
//
import { Container, Row, Col } from "react-bootstrap";
// import { BaseService } from '../../services/base-service';
// import { Link } from "react-router-dom";
import { GoogleMap } from "./google-map";
import { FacebookPagePlugin } from "./facebook-page-plugin";
import { BaseService } from "../../services/base-service";
import { Link } from "react-router-dom";

export class Footer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      target: "_blank",
    };
  }

  FB = window.FB;
  componentDidMount() {
    try {
      this.FB.XFBML.parse();
    } catch (error) {}
  }

  getMapElement = () => {
    const element = (
      <>
        <div className="footer-info-div footer-info-map-div">
          <GoogleMap src={this.props.footerInfoData.googleMapSrc} />
        </div>
      </>
    );
    return element;
  };

  getContactElement = () => {
    const element = this.props.footerInfoData.contactInfos ? (
      <div className="footer-info-div footer-info-content-div">
        <div className="footer-title-div">Liên hệ</div>
        <div>
          {this.props.footerInfoData.contactInfos.map((item) => (
            <>
              <div className="footer-i-info-div">
                <i className="footer-i-info-i far fa-circle"></i> {item.text}:{" "}
                {!BaseService.stringIsNullOrWhiteSpace(item.path) ? (
                  <>
                    {item.path.includes("http:") ||
                    item.path.includes("https:") ? (
                      <a
                        href={item.path}
                        target={this.state.target}
                        className="footer-info-link"
                      >
                        {item.value}
                      </a>
                    ) : (
                      <Link to={item.path} className="footer-info-link">
                        {item.value}
                      </Link>
                    )}
                  </>
                ) : (
                  <>{item.value}</>
                )}
              </div>
            </>
          ))}
        </div>
      </div>
    ) : (
      <></>
    );
    return element;
  };

  getAboutElement = () => {
    const element = this.props.footerInfoData.aboutUs ? (
      <div className="footer-info-div footer-info-content-div">
        <div className="footer-title-div">Về chúng tôi</div>
        <div>
          {this.props.footerInfoData.aboutUs.map((item) => (
            <>
              {item.path.includes("http:") || item.path.includes("https:") ? (
                <a
                  href={item.path}
                  target={this.state.target}
                  className="footer-info-link"
                >
                  {item.text}
                </a>
              ) : (
                <Link to={item.path} className="footer-info-link">
                  {item.text}
                </Link>
              )}
            </>
          ))}
        </div>
      </div>
    ) : (
      <></>
    );
    return element;
  };

  getFacebookElement = () => {
    const element = (
      <>
        {!this.props.footerInfoData.socialLinks[0].isDeleted ? (
          <div className="footer-info-div footer-info-content-div footer-info-content-last-div">
            <div className="footer-facebook-page-wrapper-div">
              <FacebookPagePlugin
                faceBookName={this.props.footerInfoData.socialLinks[0].path}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  getSubSocialElement = (socialLink, iconClass) => {
    const path = socialLink.path;
    const element = (
      <>
        {!socialLink.isDeleted ? (
          <div className="footer-info-div footer-info-content-div footer-info-content-last-div">
            <div className="footer-sub-social-div">
              {path.includes("http:") || path.includes("https:") ? (
                <a href={path} target={this.state.target}>
                  <i className={iconClass}></i>
                </a>
              ) : (
                <Link to={path}>
                  <i className={iconClass}></i>
                </Link>
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    );
    return element;
  };

  getSocialElement = () => {
    const element = (
      <>
        {this.getFacebookElement()}
        <div className="footer-sub-social-wrapper-div">
          {this.getSubSocialElement(
            this.props.footerInfoData.socialLinks[1],
            "fab fa-youtube"
          )}
          {this.getSubSocialElement(
            this.props.footerInfoData.socialLinks[2],
            "fab fa-twitter"
          )}
          {this.getSubSocialElement(
            this.props.footerInfoData.socialLinks[3],
            "fab fa-instagram"
          )}
          <div className="footer-sub-social-line-div"></div>
        </div>
      </>
    );
    return element;
  };

  // getSocialElement = () => {
  //   const element =
  //     this.props.footerInfoData.socialLinks &&
  //     this.props.footerInfoData.socialLinks.lenght >= 4 ? (
  //       <>
  //         <div className="footer-info-div footer-info-content-div footer-info-content-last-div">
  //           <div className="footer-facebook-page-wrapper-div">
  //             <FacebookPagePlugin
  //               faceBookName={this.getFacebookPath(
  //                 this.props.footerInfoData.socialLinks[0].path
  //               )}
  //             />
  //           </div>
  //           <div className="footer-info-div footer-social-div">
  //             {!this.props.footerInfoData.socialLinks[1].isDeleted &&
  //             !BaseService.stringIsNullOrWhiteSpace(
  //               this.props.footerInfoData.socialLinks[1].path
  //             ) ? (
  //               <div className="footer-social-button-div footer-social-youtube-button-div">
  //                 <a
  //                   href={this.props.footerInfoData.socialLinks[1].path}
  //                   target={this.state.target}
  //                 >
  //                   <i className="footer-social-logo-i fab fa-youtube"></i>
  //                 </a>
  //               </div>
  //             ) : (
  //               <></>
  //             )}
  //             {!this.props.footerInfoData.socialLinks[2].isDeleted &&
  //             !BaseService.stringIsNullOrWhiteSpace(
  //               this.props.footerInfoData.socialLinks[2].path
  //             ) ? (
  //               <div className="footer-social-button-div footer-social-youtube-button-div">
  //                 <a
  //                   href={this.props.footerInfoData.socialLinks[3].path}
  //                   target={this.state.target}
  //                 >
  //                   <i className="footer-social-logo-i fab fa-youtube"></i>
  //                 </a>
  //               </div>
  //             ) : (
  //               <></>
  //             )}
  //             {!this.props.footerInfoData.socialLinks[3].isDeleted &&
  //             !BaseService.stringIsNullOrWhiteSpace(
  //               this.props.footerInfoData.socialLinks[3].path
  //             ) ? (
  //               <div className="footer-social-button-div footer-social-youtube-button-div">
  //                 <a
  //                   href={this.props.footerInfoData.socialLinks[3].path}
  //                   target={this.state.target}
  //                 >
  //                   <i className="footer-social-logo-i fab fa-youtube"></i>
  //                 </a>
  //               </div>
  //             ) : (
  //               <></>
  //             )}
  //           </div>
  //         </div>
  //       </>
  //     ) : (
  //       <></>
  //     );
  //   return element;
  // };

  render() {
    const html = (
      <>
        <Container
          id="userLayoutFooterContainerDiv"
          fluid={true}
          className="footer-container-div"
        >
          {this.props.footerInfoData ? (
            <Container fluid={true} className="fluid-container">
              <Row>
                <Col className="footer-col-div" md={12} lg={12} xl={5}>
                  {this.getMapElement()}
                </Col>
                <Col className="footer-col-div" md={4} lg={5} xl={2}>
                  {this.getContactElement()}
                </Col>
                <Col className="footer-col-div" md={3} lg={2} xl={2}>
                  {this.getAboutElement()}
                </Col>
                <Col className="footer-col-div" md={5} lg={5} xl={3}>
                  {this.getSocialElement()}
                </Col>
              </Row>
            </Container>
          ) : (
            <></>
          )}
        </Container>
        {/* <Container id='userLayoutFooterContainerDiv' fluid={true} className='footer-container-div'>
                <Container fluid={true} className='fluid-container'>
                    <Row className='footer-branch-row'>
                        <Col lg={9} className='footer-col-div footer-branch-logo-col'>
                            <div className='footer-branch-logo-div'>
                                { BaseService.getLogo() } Souhi Việt Nam - Mỹ Phẩm Sạch
                            </div>
                        </Col>
                        <Col lg={3} className='footer-branch-email-input-col'>
                            <div className='footer-email-textbox-title-div'>Đăng ký để nhận thông tin, khuyến mãi mới nhất:</div>
                            <div className='footer-email-textbox-div'>
                                <input
                                    value={this.state.email}
                                    onChange={this.changeEmail}
                                    className='footer-email-textbox-input'
                                />
                                <div className='footer-email-textbox-send-button-div'>
                                    <i className='far fa-paper-plane'></i>
                                </div>
                            </div>
                        </Col>
                        <div className='footer-row-hr-div'></div>
                    </Row>
                    <Row>
                        <Col className='footer-col-div' lg={3}>
                            <div className='footer-info-div'>
                                <div className='footer-title-div'>
                                    Liên hệ
                                </div>
                                <div>
                                    <div className='footer-i-info-div'><i className='footer-i-info-i fas fa-map-marker'></i> Địa chỉ: 173/1 đường Lê Đình Cẩn, KP6, Quận Bình Tân, TP.HCM</div>
                                    <div className='footer-i-info-div'><i className='footer-i-info-i fas fa-phone-alt'></i> Hotline: 0905.324.769</div>
                                    <div className='footer-i-info-div'><i className='footer-i-info-i fas fa-envelope'></i> Email: myphamhariwon01@gmail.com</div>
                                    <div className='footer-i-info-div'><i className='footer-i-info-i fas fa-globe-americas'></i> Website: https://myphamhariwon.com/</div>
                                </div>
                            </div>
                        </Col>
                        <Col className='footer-col-div' lg={6}>
                            <div className='footer-info-div footer-info-map-div'>
                                <GoogleMap src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6935188952193!2d106.60427471462076!3d10.758086862474098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752c4f211ccfc9%3A0x873002941d1e21d!2zQ8O0bmcgVHkgVG5oaCBUaMawxqFuZyBN4bqhaSBYdeG6pXQgTmjhuq1wIEto4bqpdSBN4bu5IFBo4bqpbSBIYXJpIFdvbg!5e0!3m2!1sen!2s!4v1588134022465!5m2!1sen!2s' />
                            </div>
                        </Col>
                        <Col className='footer-col-div' lg={3}>
                            <div className='footer-info-div'>
                                <div className='footer-title-div'>
                                    Về chúng tôi
                                </div>
                                <div>
                                    <Link to='/#' className='footer-info-link'>Giới thiệu Souhi Việt Nam</Link>
                                    <Link to='/#' className='footer-info-link'>Văn hóa Souhi Việt Nam</Link>
                                    <Link to='/#' className='footer-info-link'>Chính sách bảo mật</Link>
                                    <Link to='/#' className='footer-info-link'>Tuyển dụng</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className='footer-social-row'>
                        <div className='footer-row-hr-div'></div>
                        <Col>
                            <div className='footer-info-div footer-social-div'>
                                <div className='footer-title-div'>
                                    <i className='fas fa-globe fa-globle'></i>
                                </div>
                                <div>
                                    <i className='footer-social-logo-i fab fa-facebook'></i>
                                    <i className='footer-social-logo-i fab fa-youtube'></i>
                                    <i className='footer-social-logo-i fab fa-twitter'></i>
                                    <i className='footer-social-logo-i fab fa-instagram'></i>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container> */}
      </>
    );
    return html;
  }
}
