import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./components/app";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '30px', flexDirection: 'column', textAlign: 'center', fontSize: '24px', lineHeight: '45px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', width: '150px', height: '150px', borderRadius: '50%', marginTop: '45px', marginBottom: '15px', border: '12px solid #7F35B2', opacity: '.5' }}>
            <i class="fas fa-truck-loading" style={{ fontSize: '60px', position: 'absolute', color: '#7F35B2' }}></i>
          </div>
          Chúng tôi đang xây dựng nội dung trang web<br />
          Xin vui lòng quay lại sau.<br />
          Xin cảm ơn!
        </div> */}
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
