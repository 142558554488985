import React from "react";
import "./product-list.css";
//
// import flash from "../../assets/images/gif/flash.gif";
// import hot from "../../assets/images/gif/hot.gif";
import { BaseService } from "../../services/base-service";
import { RequestStatus } from "../../constants";
import { ProductCategoryItem } from "./product-category-item";
import { Tabs, Tab } from "react-bootstrap";
import {
  FormCountdownClock,
  FormCountdownClockNew,
} from "../public/form-components";

export class ProductList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      topProductFilter: "hotProduct",
      topProductList: [],
      productCategoryList: [],
      isShowCountdownLock: false,
    };
  }

  componentDidMount() {
    this.getTopProductList();
    const productCategoryList = this.props.productCategoryList;
    let res = BaseService.getOrderListWithName(productCategoryList);
    this.setState({
      productCategoryList: res,
    });
    this.handleResize();
  }

  componentWillReceiveProps(nextProps) {
    // if(
    //     (this.props.productCategoryListRequestStatus !== nextProps.productCategoryListRequestStatus && nextProps.productCategoryListRequestStatus === RequestStatus.RQ_SUCCESS) ||
    //     (this.props.productListRequestStatus !== nextProps.productListRequestStatus && nextProps.productListRequestStatus === RequestStatus.RQ_SUCCESS)
    // ) {
    // setTimeout(() => {
    //     console.log('ProductList - componentWillReceiveProps');
    //     this.handleResize();
    //     window.addEventListener('resize', this.handleResize);
    // }, 0);
    // }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    setInterval(() => {
      BaseService.setMaxHeightByClassName(
        "product-list-catrgory-background-img"
      );
      BaseService.setMaxHeightByClassName("product-list-carousel-title-div");
      // BaseService.setMaxHeightByClassName('product-list-catrgory-tag-div');
      BaseService.setMaxHeightByClassName("product-list-carousel-price-div");
      BaseService.setMaxHeightByClassName(
        "product-list-carousel-promo-text-div"
      );
      BaseService.setHeightByWidthOfClass("product-list-carousel-item-img");
      BaseService.setHeightByWidthOfClass("product-list-carousel-item-img");
      // BaseService.setMaxWidthByClassName('product-item-cart-item-div');
    }, 500);
  };

  getProductCategoryElement = (productCategoryItem) => {
    const productItemListByCategory = this.props.productList.filter(
      (t) =>
        t.categoryId === productCategoryItem.id ||
        (t.saleInfo &&
          t.saleInfo.subCategories &&
          t.saleInfo.subCategories.findIndex(
            (k) => k.value === productCategoryItem.id
          ) !== -1)
    );
    if (productItemListByCategory && productItemListByCategory.length > 0) {
      return (
        <ProductCategoryItem
          windowWidth={this.props.windowWidth}
          productCategoryItem={productCategoryItem}
          productItemList={productItemListByCategory}
          productColumns={12}
          addProductItemToCart={this.props.addProductItemToCart}
        />
      );
    } else {
      return <></>;
    }
  };

  getTopProductList = () => {
    this.setState(
      {
        isShowCountdownLock: false,
      },
      () => {
        let topProductList = [];
        switch (this.state.topProductFilter) {
          // case "newProduct": {
          //   topProductList = this.props.productList.filter(
          //     (t) => t.isNew === true
          //   );
          //   break;
          // }
          case "hotProduct": {
            topProductList = this.props.productList.filter(
              (t) => t.isHot === true
            );
            break;
          }
          // case "saleProduct": {
          //   this.setState({
          //     isShowCountdownLock: true,
          //   });
          //   topProductList = this.props.productList
          //     .filter((t) => t.saleInfo && t.saleInfo.sale11 === true)
          //     .map((t) => {
          //       t.orderNumber = t.saleInfo.sale11OrderNumber
          //         ? t.saleInfo.sale11OrderNumber
          //         : 0;
          //       return t;
          //     });
          //   topProductList = BaseService.getOrderListWithName(topProductList);
          //   break;
          // }
          default: {
          }
        }
        this.setState({
          topProductList: topProductList,
        });
      }
    );
  };

  handleTopProductFilterSelect = (value) => {
    this.setState(
      {
        topProductFilter: value,
      },
      () => {
        this.getTopProductList();
      }
    );
  };

  getTabTitleName = (text, html) => {
    const element = <>{this.props.windowWidth >= 768 ? text : html}</>;
    return element;
  };

  getSale11ProductList = () => {
    let sale11ProductList = [];
    if (this.props.productListRequestStatus === RequestStatus.RQ_SUCCESS) {
      sale11ProductList = this.props.productList
        .filter((t) => t.saleInfo && t.saleInfo.sale11 === true)
        .map((t) => {
          t.orderNumber = t.saleInfo.sale11OrderNumber
            ? t.saleInfo.sale11OrderNumber
            : 0;
          return t;
        });
      sale11ProductList = BaseService.getOrderListWithName(sale11ProductList);
    }
    return sale11ProductList;
  };

  render() {
    const html = (
      <>
        <div id="productListContainerDiv" className="component-container-div">
          {/* <div id='productListContainerTitleDiv' className='component-title-div' style={{
                    backgroundImage: `url('${this.props.home_page_title_jpg}')`
                }}>
                    <div className='component-title-text-div'>
                        <div>
                            Sản phẩm
                            <div className='component-title-hr'></div>
                        </div>
                    </div>
                </div> */}
          <div className="product-list-sale11-products-container-div">
            <br />
            <FormCountdownClockNew
              title="Siêu Sale Khai Trương"
              startDate="2021/07/15"
              diffDate={3}
            />
            <ProductCategoryItem
              windowWidth={this.props.windowWidth}
              productCategoryItem={{
                id: -2,
                imgURL: null,
                orderNumber: -2,
                tags: [],
                title: "",
              }}
              productColumns={12}
              addProductItemToCart={this.props.addProductItemToCart}
              productFilterTitle={<></>}
              productItemList={this.getSale11ProductList()}
            />
          </div>
          {/* <ProductCategoryItem
            windowWidth={this.props.windowWidth}
            productCategoryItem={{
              id: -1,
              imgURL: null,
              orderNumber: -1,
              tags: [],
              title: "",
            }}
            productColumns={12}
            addProductItemToCart={this.props.addProductItemToCart}
            productFilterTitle={
              <>
                <div className="tabs-container-div tabs-container-no-content-div product-list-tab-container-div">
                  <div className="component-title-text-div component-kols-title-text-div">
                    <div style={{ marginBottom: '45px' }}>
                      Sản phẩm nổi bật
                    </div>
                  </div>
                  {this.state.isShowCountdownLock ? (
                    <div>
                      <br />
                      <FormCountdownClock
                        title="Chỉ còn"
                        startDate="2020/11/01"
                        diffDate={3}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            }
            productItemList={this.state.topProductList}
          /> */}
          {this.state.productCategoryList.map((productCategoryItem, index) => (
            <React.Fragment key={index}>
              {this.getProductCategoryElement(productCategoryItem)}
            </React.Fragment>
          ))}
        </div>
      </>
    );
    return html;
  }
}
